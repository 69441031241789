import React, {createContext, useContext, useReducer} from 'react';

export const AppDefault = {
  notifications: [],
  user: {},
  organization: 'vmi'
};

export const AppContext = createContext(AppDefault);

export const AppProvider = ({reducer, initialState, children}) => {
  return (
  <AppContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </AppContext.Provider>
  )};

export const useAppValue = () => useContext(AppContext);
export const AppConsumer = AppContext.Consumer;

export const AppReducer = (state, action) => {
  switch(action.type) {
    case 'addNotification':
      const notification = {
        id: Math.floor(Math.random() * 10000),
        content: action.content 
      };

      return {
        ...state,
        notifications: [...state.notifications, notification]
      };
    case 'removeNotification':
      return {
        ...state,
        notifications: state.notifications.filter((item) => item.id !== action.id)
      }
    case 'setUser':
      return {
        ...state,
        user: action.user
      }
    case 'removeChangeRequest':
      return {
        ...state,
        user: {
          ...state.user,
          changeRequest: [],
          changeRequestTime: null
        }
      }
    default:
      return state;
  }
}

export default AppContext;