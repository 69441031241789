import React from "react";
import ViewTable from "./ViewTable";

import { TableProvider, TableDefault, TableReducer } from '../components/common/TableContext';

const AccessRequests = () => (
  <TableProvider
    initialState={TableDefault}
    reducer={TableReducer}
    >  
    <ViewTable
      title="Access Requests"
      availableFilterStatuses={["PENDING","DEFERRED","DENIED"]}
      availableChangeStatuses={["PENDING","DEFERRED","DENIED"]}
      filterStatusDefault="PENDING"
      exportProps={{
        status: "PENDING"
      }}
      />
  </TableProvider>
);

export default AccessRequests;
