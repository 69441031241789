import React from 'react';
import { HashRouter as Router, Route } from "react-router-dom";

import { withAuthenticator } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import { adminConfig } from './config/aws';

import routes from "./routes";
import withTracker from "./withTracker";

import "./bootstrap.min.css";
import "./shards-dashboards.1.0.1.min.css";

import { AppProvider, AppDefault, AppReducer } from './AppContext';
import { TableProvider, TableDefault, TableReducer } from './components/common/TableContext';
import Notifications from './components/common/Notifications';

Amplify.configure( adminConfig );

function App() {
  return (
    <AppProvider
      initialState={AppDefault}
      reducer={AppReducer}
      >
      <TableProvider
        initialState={TableDefault}
        reducer={TableReducer}
        >        
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <div>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={withTracker(props => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} />
                      </route.layout>
                    );
                  })}
                />
              );
            })}
          </div>
        </Router>
      </TableProvider>
      <Notifications />
    </AppProvider>
  );
}

export default withAuthenticator(App);
