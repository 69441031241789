import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Card,
  CardBody,
  Button,
  FormRadio,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput
  } from "shards-react";
import { useHistory } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/common/Table";
import { LoadingSpinner } from '../components/common/LoadingAnimation';
import Copy from '../utils/config/Copy';
import Config from '../utils/config/Config';
import { useAppValue } from '../AppContext';
import { GetNotificationRequests } from '../utils/zuriapi';
import dayjs from 'dayjs';

const Notifications = () => {
  const [ notificationList, setNotificationList ] = useState([]);
  const [ isUpdating, setIsUpdating ] = useState(true);
  const router = useHistory();
  const appValue = useAppValue();
  const dispatch = appValue[1];

  /**
   * Onload, get all uploads
   */
  useEffect(() => {
    getNotifications()

    //setInterval(getUploads, 60000);
  }, []);

  /**
   * [description]
   * @return {[type]} [description]
   */
  const getNotifications = () => {
    GetNotificationRequests()
      .then(res => {
        console.log(res);
        if(res.data && res.data.length){
          //make date strings readable
          res.data.map((item) => {
            console.log(item);
            item.sendDate = dayjs(item.createDate).format(Copy.datetimeFormat);
            item.chapters = item.criteria.chapters.join(', ');
            item.classes = item.criteria.classes.join(', ');
            item.recipients = item.stats ? item.stats.recipients : 0;
            item.pushTotal = item.stats ? item.stats.pushRecipients : 0;
            return item;
          })

          setNotificationList(res.data)          
        }
        setIsUpdating(false);
      })
      .catch(error => {
        setIsUpdating(false);
      })    
  }

  const handleClick = (res) => {
    router.push('/alumni-notifications/new');
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={Copy.notificationsTitle} className="text-sm-left" />
      </Row>
      {
        //notificationList.length ? 
          notificationsTable(notificationList, handleClick)
        //  : React.Fragment      
      }
    </Container>
  );
};

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const notificationsTable = ( data, handleClick ) => {  
  const refreshButton = (
    <Button
      key="uploadsTable-refresh"
      className="button-refresh"
      onClick={handleClick}
      >Create New Notification</Button>
    );

  return Table({
    title: "Notifications",
    note: ["Showing 1 year of past notifications", refreshButton],
    data: data,
    columnKeys: ["name", "title", "body", "sendDate", "classes", "chapters", "recipients", "pushTotal"],
    columnTitles: ["Reference Name", "Title", "Message" ,"Send Date", "Classes", "Chapters", "Recipients", "Pushed"]
    });
}

export default Notifications;