import React, { useState } from 'react';
import { 
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput
  } from 'shards-react';
import { Copy } from "../../utils/config"
import { SetPassword } from '../../utils/zuriapi';
import { useAppValue } from '../../AppContext';


const SetPasswordModal = (props) => {
  const [ newPassword, setNewPassword ] = useState("");
  const [ context, dispatch ] = useAppValue(); 
  const [ error, setError ] = useState(false);

  const submitPassword = () => {
    setError(false)
    SetPassword(props.username, newPassword)
      .then((res) => {
        switch(res.status){
          case 400:
            let message = res.data.error.message;
            setError(message);
            break;
          case 200:
            dispatch({
              type: "addNotification",
              content: Copy.notifications.passwordSet
            })

            props.setShow(false);
            break;
        }

      });
  }

  return(
    <Modal
      open={props.show}
      toggle={() => props.setShow(false)}
      >
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <div>
          <label>{Copy.setPasswordPrompt}</label>
          <FormInput
            onChange={(e) => { setNewPassword(e.target.value) }}
            />
        </div>
        {
          error ?
            <p 
              style={{
                color: "#ad1535",
                fontSize: "0.8em",
                marginTop: "1em"
              }}>{error}</p>
            : <React.Fragment />
        }    
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em"
          }}
          >
          <Button
            theme="danger"
            outline={false}
            disabled={false}
            onClick={() => submitPassword()}
            >{Copy.buttonSubmit}</Button>
          <Button
            theme="secondary"
            outline
            onClick={() => props.setShow(false)}
            >{Copy.buttonCancel}</Button>
        </div>        
      </ModalBody>
    </Modal>  
  );    
}

export default SetPasswordModal;