import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import Dashboard from './views/Dashboard';
import Alumni from './views/Alumni';
import AccessRequests from './views/AccessRequests';
import UpdateRequests from './views/UpdateRequests';
import Import from './views/Import';
import Export from './views/Export';
import Notifications from './views/Notifications';
import NewNotification from './views/NewNotification';
import User from './views/User';
import PrivacyChanges from './views/PrivacyChanges';


import ComponentsOverview from './views/ComponentsOverview';

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/alumni",
    layout: DefaultLayout,
    component: Alumni
  },  
  {
    path: "/alumni-updates",
    layout: DefaultLayout,
    component: UpdateRequests
  },
  {
    path: "/alumni-requests",
    layout: DefaultLayout,
    component: AccessRequests
  },
  {
    path: "/alumni-privacy",
    layout: DefaultLayout,
    component: PrivacyChanges
  },
  {
    path: "/alumni-import",
    layout: DefaultLayout,
    component: Import
  },
  {
    path: "/alumni-export",
    layout: DefaultLayout,
    component: Export
  },
  {
    path: "/alumni-notifications/new",
    layout: DefaultLayout,
    component: NewNotification,
    exact: true
  },
  {
    path: "/alumni-notifications",
    layout: DefaultLayout,
    component: Notifications,
    exact: true
  },
  {
    path: "/user/:userId",
    layout: DefaultLayout,
    component: User
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  }
];
