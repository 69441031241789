import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

export default function TableBody(props){
  const hasRowActions = typeof props.rowActions !== "undefined";

  /**
   * [arrayToColumns description]
   * @param  {[type]} row      [description]
   * @param  {[type]} rowIndex [description]
   * @return {[type]}          [description]
   */
  function arrayToColumns(row, rowIndex){
    return row.map((column, colIndex) => (
      <td
        key={props.tableId + "-" + rowIndex + "-" + colIndex}
        >{column}</td>
      ))    
  }

  /**
   * [keysToColumns description]
   * @param  {[type]} keys     [description]
   * @param  {[type]} row      [description]
   * @param  {[type]} rowIndex [description]
   * @return {[type]}          [description]
   */
  function keysToColumns(keys, row, rowIndex){
    return keys.map((key, keyIndex) => {
      let rowValue = row[key];

      //apply a column link if available
      if(props.columnLinks && props.columnLinks[key]){
        switch(props.columnLinks[key]){
          case "profileUrl":
            rowValue = (<Link to={"/user/" + row.id}>{rowValue}</Link>)
            break;
          default:
            //do nothing
            break;
        }
      }
      
      return (<td
        key={props.tableId + "-" + rowIndex + "-" + keyIndex}
        >{rowValue}</td>)
    })
  }

  /**
   * render
   */
  return(
    <tbody>
    {
      props.data && props.data.map ? 
        props.data.map( (row, rowIndex) => {
          return (
            <Fragment
              key={"tb"+rowIndex}
              >
              <tr
                key={props.tableId + "-" + rowIndex}
                >                  
                {props.columnKeys ? 
                  keysToColumns(props.columnKeys, row, rowIndex)
                  : arrayToColumns(row, rowIndex)
                }
              </tr>
              {
                !hasRowActions ?
                  <Fragment />
                  : <tr
                    className="table-row-actions"
                    key={props.tableId + "-" + rowIndex + "-actions"}
                    >
                    <td
                      className="border-0 pt-0 pb-1 small"
                      >&nbsp;</td>
                    <td 
                      colSpan="5"
                      className="border-0 pt-0 pb-1 small"
                      >
                      {props.rowActions(row)}
                    </td>
                  </tr>
              }
            </Fragment>                   
            )
        })
        : <Fragment />
    }
    </tbody>
  )
}