import React from "react";

const FileUpload = (props) => {
  return (
    <div className="custom-file mb-3">
      <input 
        type="file"
        className="custom-file-input"
        name={props.name}
        accept=".csv"
        id={props.name + "-input"}
        onChange={(e) => {
          const file = e.target.files.length ? e.target.files[0] : false;
          // setFile(file);

          if(typeof props.onChange === "function"){
            props.onChange(file);
          }
        }}
        />
      <label
        className="custom-file-label"
        htmlFor={props.name + "-input"}
        >
        {props.value ? props.value.name : props.label}
      </label>
    </div>
)};

export default FileUpload;

FileUpload.defaultProps = {
  name: "upload",
  label: "Choose file..."
}