import React, { useEffect, useState } from 'react';
import Table from '../common/Table';

import { FindAlums } from '../../utils/zuriapi';

const defaultColumnTitles = [ "Name", "Year" ];
const defaultColumnKeys = [ "nameFull", "clientClassyear" ];

export default function Overview(props){
  const [ data, setData ] = useState([]);

  useEffect(() => {
    async function fetchData(){
      //pass through any intrinsic filters
      const params = props.filters ? props.filters : {};

      const alums = await FindAlums(params);
      let result = {};

      if(alums.data){
        result = alums.data;
      }
      else{
        console.log("overview err");
      }

      setData(result);
    }

    fetchData();
  }, [props.filters]);

  return (
    <Table 
      {...props}
      data={props.rowProcess ? data.map(props.rowProcess) : data}
      columnTitles={props.columnTitles ? props.columnTitles : defaultColumnTitles}
      columnKeys={props.columnKeys ? props.columnKeys : defaultColumnKeys}
      />);
}