import React, { useEffect } from 'react';
import { useAppValue } from '../../AppContext';

export default function Notification({id, content}){
  const [ {notifications}, dispatch ] = useAppValue();

  //remove the notification
  const remove = () => {
    dispatch({
      type: 'removeNotification',
      id: id
      })
  }

  //close notification after 10 seconds
  useEffect(() => {
    setTimeout(remove, 10000);    
  }, [])

  return(
    <p 
      className="Notifications-message"
      key={id}
      onClick={() => remove()}
      >{content} <span className="Notifications-close">&times;</span></p>
    );
}