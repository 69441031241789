import React, { useState } from "react";
import ViewTable from "./ViewTable";
import { 
  Button,
  Modal,
  ModalBody,
  ModalHeader
  } from 'shards-react';
import { Copy } from "../utils/config"
import ConfirmModal from '../components/common/ConfirmModal';
import { useAppValue } from '../AppContext';
import { RemoveAlumPrivacyRequest } from '../utils/zuriapi';

import { TableProvider, TableDefault, TableReducer } from '../components/common/TableContext';

const PrivacyChanges = () => {
  const [ context, dispatch ] = useAppValue();
  const titles = [ 'Name', 'Email', 'Join Date', 'Class Year' ];
  const columns = [ 'nameFull', 'emailPrimary', 'registerDate', 'clientClassyear' ];
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ selectedAlums, setSelectedAlums ] = useState([]);
  const [ clearCallback, setClearCallback ] = useState({});

  const maybeMarkChangesComplete = (selected, callback) => {
    if(!selected || selected.length === 0){
      dispatch({
        type: 'addNotification',
        content: Copy.notifications.mustSelectUser
      })
    }
    else {
      // console.log("maybe", selected, callback);

      setSelectedAlums(selected);
      setClearCallback({callback: callback});
      setShowConfirm(true);
    }    
  }

  const markChangesComplete = () => {
    let promises = selectedAlums.map(RemoveAlumPrivacyRequest);
    Promise.all(promises)
      .then(res => {

        if(typeof clearCallback.callback === "function"){
          clearCallback.callback();            
        }

        setShowConfirm(false);
        setSelectedAlums([]);
        setClearCallback({});        
      })
  }

  return (
  <TableProvider
    initialState={TableDefault}
    reducer={TableReducer}
    >  
    <ViewTable
      title={Copy.pagePrivacyTitle}
      filters={{
        status: "APPROVED",
        privacyNewChanges: "TRUE"
        }}
      exportProps={{
        status: "APPROVED",
        privacyNewChanges: "TRUE",
        columns: ["id","nameFirst","nameLast","privateFields"]
      }}
      actions={[{
        button:(props) => (
        <Button 
          theme="secondary"
          {...props}
          >
          <i className="material-icons mr-1">check_box</i> {Copy.buttonClearChanges}
        </Button>),
        action: maybeMarkChangesComplete
        }]}
      titles={titles}
      columns={columns}
      disable={["set-status","filter-status"]}
      />
    <ConfirmModal
      show={showConfirm}
      setShow={setShowConfirm}
      title={Copy.confirmClearPrivacyTitle}
      prompt={Copy.confirmClearPrivacyPrompt}
      onConfirm={() => markChangesComplete()}
      />
  </TableProvider>
  )
};


export default PrivacyChanges;
