import React from 'react';
import { 
  Button,
  Modal,
  ModalBody,
  ModalHeader
  } from 'shards-react';
import { Copy } from "../../utils/config"


const ConfirmModal = (props) => {
  return(
    <Modal
      open={props.show}
      toggle={() => props.setShow(false)}
      >
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <p>{props.prompt}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em"
          }}
          >
          <Button
            theme="danger"
            outline={false}
            disabled={false}
            onClick={props.onConfirm}
            >{Copy.buttonConfirm}</Button>
          <Button
            theme="secondary"
            outline
            onClick={() => props.setShow(false)}
            >{Copy.buttonCancel}</Button>
        </div>        
      </ModalBody>
    </Modal>  
  );    
}

export default ConfirmModal;