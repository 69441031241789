import React from 'react';
import { Config } from '../../utils/config';
import ProfileGroup from './ProfileGroup'; 

const Profile = (props) => {
  let content = [];

  for(let i = 0; i < Config.profileGroups.length; i++){
    content.push(
      <ProfileGroup 
        group={Config.profileGroups[i]}
        profile={props.profile}
        key={i}
        baseKey={i}
        />
      )
  }

  return(
    <div
      className="Profile"
      >
      {content}
    </div>
    )
}

export default Profile;