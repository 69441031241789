import React, { useState } from 'react';
import { 
  Form,
  FormInput,
  InputGroup,
  InputGroupAddon,
  Button,
  ButtonGroup
  } from "shards-react";
import { useHistory } from "react-router-dom";
import { useAppValue } from '../../AppContext';
import ConfirmModal from '../common/ConfirmModal';
import { FindAlums, MatchAlum } from '../../utils/zuriapi';
import LoadingAnimation from '../common/LoadingAnimation';
import { Copy } from "../../utils/config"

const searchTypes = [ "Name", "Email"];

const UserSearch = (props) => {
  const appValue = useAppValue();
  const dispatch = appValue[1];
  const [ activeType, setActiveType ] = useState(searchTypes[0]);
  const [ searchname, setSearchname ] = useState();
  const [ searchemail, setSearchemail ] = useState();
  const [ searchResults, setSearchResults ] = useState([]);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ match, setMatch ] = useState();

  const history = useHistory();

  /**
   * [description]
   * @return {[type]} [description]
   */
  const searchByName = (e) => {
    e.preventDefault();

    doSearch({
      nameSearchable: searchname
    })
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  const searchByEmail = (e) => {
    e.preventDefault();

    doSearch({
      emailPrimary: searchemail
    })    
  }

  /**
   * [description]
   * @param  {[type]} params [description]
   * @return {[type]}        [description]
   */
  const doSearch = (params) => {
    setIsLoading(true);
    params.status = "UNCLAIMED";
    params.sort = "score";

    FindAlums(params)
      .then((res) => {
        setIsLoading(false)

        if(res.data && res.data.length){
          setSearchResults(res.data);
        }
        else{
          setSearchResults([]);
          dispatch({
            type: 'addNotification',
            content: "No results found"
          })          
        }
      })
      .catch((err) => {
        console.log("find error", err);
      })
  }

  const maybeMakeMatch = (user) => {
    setShowConfirm(true);
    setMatch(user);
  }

  /**
   * [description]
   * @param  {[type]} userId [description]
   * @return {[type]}        [description]
   */
  const makeMatch = () => {
    MatchAlum(props.currentUser, match.id)
      .then((res) => {
        if(res.data && res.data.to){
          history.push({
            pathname: '/user/'+res.data.to
          });

          if(typeof props.updateUser === "function"){
            props.updateUser(res.data.to);
          }
          

          dispatch({
            type: 'addNotification',
            content: res.data.message ? res.data.message : "User matched"
          })          
        }
        else{
          dispatch({
            type: 'addNotification',
            content: "Match failed"
          })          
        }
      })
      .catch((err) => {
        dispatch({
          type: 'addNotification',
          content: err.message ? err.message : "Match error"
        })
      });

  }

  /**
   * [description]
   * @param  {[type]} type [description]
   * @return {[type]}      [description]
   */
  const formSwitch = (type) => {
    switch(type){
      case "Name":
        return(
          <form
            className={formClass}
            onSubmit={searchByName}
            >
            <InputGroup className="ml-auto">
              <FormInput 
                placeholder="Enter name" 
                onChange={(e) => setSearchname(e.target.value)}
                />
              <InputGroupAddon type="append">
                <Button theme="primary" className="px-2">
                  <i className="material-icons">search</i> Search
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </form>        
          )

      case "Email":
        return(
          <form
            className={formClass}
            onSubmit={searchByEmail}
            >
            <InputGroup className="ml-auto">
              <FormInput 
                placeholder="Enter email" 
                value={searchemail}
                onChange={(e) => setSearchemail(e.target.value)}
                />
              <InputGroupAddon type="append">
                <Button theme="primary" className="px-2">
                  <i className="material-icons">search</i> Search
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </form>
          )
      default:
        break;

    }
  }
  
  /**
   * Render
   */
  return (
    <div className="UserSearch">
      <ButtonGroup>
      {
        searchTypes.map( ( type ) => (
          <Button
            outline={activeType !== type}
            theme="secondary"
            onClick={() => {setActiveType(type)}}
            key={"typeselect-"+type}
            >{type}</Button>
          ))
      }
      </ButtonGroup>
      {formSwitch(activeType)}
      {
        isLoading ?
          <LoadingAnimation />
          : 
          searchResults.length ?
            <div className="usersearch">
              <table className="table mb-0">
                <tbody>
                  {searchResults.map((row, index) => (
                    <tr key={"usersearch-"+index}>
                      <td>
                        <span className="font-weight-bold">{row.nameFull}</span>
                        <br /><span className="text-muted">{row.emailPrimary}</span>
                      </td>
                      <td className="usersearch-classyear">{row.clientClassyear}</td>
                      <td className="usersearch-match">
                        <Button
                          theme="secondary"
                          onClick={() => maybeMakeMatch(row)}
                          key={"matchrow-"+row.id}
                          >Match</Button>
                      </td>
                    </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
            :
            <React.Fragment />
      }
      <ConfirmModal
        show={showConfirm}
        setShow={setShowConfirm}
        title={Copy.confirmMatch}
        prompt={
          match && match.nameFull ? `Are you sure you want to match with ${match.nameFull}?`
            : "Are you sure you want to match these records?"
        }
        onConfirm={() => makeMatch()}
        />


    </div>
  )

}

const formClass = "mt-2";

export default UserSearch;