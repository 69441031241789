import React, { Fragment } from 'react';
import { 
  Card, 
  CardHeader, 
  CardBody, 
  FormCheckbox 
} from "shards-react";
import { Link } from "react-router-dom";
import TableBody from './TableBody';

export default function Table(props){

  const tableId = Math.random() * 10000;

  return (
    <Card small className="mb-4">
      {props.title ? 
        <CardHeader className="border-bottom tableCard-header">
          <h6 className="m-0">{props.title}</h6>
          {
            props.note ?
              <span className="tableCard-note">{props.note}</span>
              : null
          }
          {
            props.action ?
              typeof props.action.push === "function" ?
              props.action.map( ({action, label}) => (
                <Link
                  className="btn btn-primary"
                  to={action}
                  >{label}</Link>
                ) )
              : (
                <Link
                  className="btn btn-primary"
                  to={props.action.action}
                  >{props.action.label}</Link>
                )
            : <Fragment />
          }
        </CardHeader>
        : <Fragment />
      }
      <CardBody className="p-0 pb-3">
        <table className="table mb-0">
          {
            props.columnTitles ?
              <thead className="bg-light">
                <tr>
                  {props.addCheckboxes ? 
                    <th scope="col" className="border-0">
                      <FormCheckbox />
                    </th>
                    : <Fragment />
                  }
                  {
                    props.columnTitles.map((columnTitle, index) => {
                      return (
                      <th 
                        scope="col"
                        className="border-0 table-header"
                        key={tableId + "-h-" + index }
                        >{columnTitle}</th>
                      )})
                  }
                </tr>
              </thead>
              : <Fragment />
          }
          <TableBody
            {...props}
            tableId={tableId}
            data={props.data}
            columnKeys={props.columnKeys}
            rowIndexKey={"id"}
            addCheckboxes={props.addCheckboxes}
            rowActions={props.rowActions}
            />
        </table>
      </CardBody>
    </Card>
    );  
}

Table.defaultProps = {
  addCheckboxes: false
}