import React, { useRef, useState } from 'react';
import { 
  Form,
  FormSelect,
  FormInput,
  Button,
  Modal,
  ModalBody
} from 'shards-react';
import './form.css';
import LoadingAnimation from '../common/LoadingAnimation';
import { useTableValue } from '../common/TableContext';
import { useAppValue } from '../../AppContext';
import { Statuses, Copy } from '../../utils/config';
import { setAlumsStatus, GetExport, GetExportFileUrl, GetUpdatefile } from '../../utils/zuriapi';

export default function TableFilter(props){
  const [ {selected, filterName, filterStatus, data}, tableDispatch] = useTableValue();
  const appValue = useAppValue();
  const appDispatch = appValue[1];
  const setStatusRef = useRef();
  const [ isExporting, setIsExporting ] = useState(false);
  const [ exportLink, setExportLink ] = useState(false);

  var filterNameTimer = null;
  const availableChangeStatuses = props.availableChangeStatuses ? 
    props.availableChangeStatuses
    : ['PENDING', 'DENIED', 'DEFERRED', 'DELETED']
  const availableFilterStatuses = props.availableFilterStatuses ? 
    props.availableFilterStatuses
    : Statuses.map((item) => item.value);    

  /**
   * [onUpdateStatus description]
   * @return {[type]} [description]
   */
  function onUpdateStatus(){
    const status = setStatusRef ? setStatusRef.current.value : false;

    if(!status){
      appDispatch({type: "addNotification", content: "Please select a status"});
    }
    if(!selected.length){
      appDispatch({type: "addNotification", content: Copy.notifications.mustSelectUser});
    }

    if(!!status && !!selected.length){
      setAlumsStatus(selected, status)
        .then((res) => {
          const newData = data.map((item) => {
            if(selected.indexOf(item.id) > -1){
              item.status = status
            }

            return item
          });

          tableDispatch({
            type: "setData",
            data: newData
          });
        });
    }
  }

  /**
   * [onGetDownload description]
   * @return {[type]} [description]
   */
  function onGetDownload(query){
    query = query ? query : {};
    setIsExporting(true);
    if(props.filters){
      query = Object.assign(query, props.filters);
    }
    if(props.exportProps){
      query = Object.assign(query, props.exportProps);
    }

    //clear out the nameSearchable wildcard if already set.
    if(query && query.nameSearchable && query.nameSearchable === "*"){
      delete query.nameSearchable;
    }

    if(!selected.length){
      if(filterName){
        query.nameSearchable = filterName;
      }
      else{
        query.nameSearchable = "*";
      }

      if(filterStatus){
        query.status = filterStatus;
      }
    }
    else{
      query.id = selected.join(",");
    }

    if(props.exportAction){
      props.exportAction(query);
    }
    else {
      GetExport(query)
        .then((res) => {
          if(res.data && res.data.filename){

            let delay = 1000;
            let fileKey = res.data.filename;
            let fileParts = fileKey.split("/");
            let fileName = fileParts[fileParts.length - 1];

            const checkFile = function(){
              GetUpdatefile({filename: fileName})
                .then((res) => {
                  if(res.data && res.data.status === "exported"){
                    GetExportFileUrl(fileKey)
                      .then((url) => {
                        setIsExporting(false);
                        window.location = url
                      })
                  }
                  else{
                    delay = Math.min(delay * 1.5, 15000);
                    timeout = setTimeout(checkFile, delay);
                  }
                })
            }

            let timeout = setTimeout(checkFile, delay);
          }
          else {
            throw new Error("Could not get export file")
          }
        })
        .catch((err) => {
          setIsExporting(false);
          appDispatch({
            type: 'addNotification',
            content: err.message
          })
        })
    }    
  }

  function onGetUserDownload(){
    onGetDownload({exportType: "username"})
  }

  /**
   * [onFilterStatus description]
   * @return {[type]} [description]
   */
  function onFilterStatus(e){
    const status = e.target.value;

    tableDispatch({type: "setFilterStatus", filterStatus: status});
  }

  /**
   * [onFilterName description]
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  function onFilterName(e){
    var searchTerm = e.target.value;

    clearTimeout(filterNameTimer);
    filterNameTimer = setTimeout(() => {
      tableDispatch({type: "setFilterName", filterName: searchTerm});
    }, 3000);
  }

  /**
   * [submitFilterName description]
   * @param  {[type]} e [description]
   * @return {[type]}   [description]
   */
  function submitFilterName(e){
    var searchTerm = e.target.value;

    if(e.key === "Enter"){
      clearTimeout(filterNameTimer);
      tableDispatch({type: "setFilterName", filterName: searchTerm});      
    }
  }

  return (
    <form 
      className="TableFilter" 
      onSubmit={e => e.preventDefault()}>
      <div className="TableFilter-actions">
        {
          !props.disable || props.disable.indexOf("set-status") === -1 ?
            <div className="TableFilter-group">
              <FormSelect 
                innerRef={setStatusRef}
                defaultValue=""
                >
                <option value="" disabled>Set Status</option>
                {Statuses.map( (status) => {
                  if(availableChangeStatuses.indexOf(status.value) > -1){
                    return (
                      <option
                        value={status.value}
                        key={status.value}
                        >{status.label}</option>
                      );                
                  }
                  else {
                    return null;
                  }
                  
                })}
              </FormSelect>
              <Button 
                theme="secondary"
                onClick={onUpdateStatus}
                >Apply</Button>
            </div>
            : null          
        }
        {
          props.actions ? props.actions.map((action, index) => {
            return action.button({
              onClick: (() => action.action(selected, props.onRefresh)),
              key: "action-"+index
            });
          })
          : null
        }        
        <Button 
          theme="secondary"
          onClick={onGetDownload}
          title={selected.length ? "Export selected alumni" : "Export alumni"}
          >
          <i className="material-icons mr-1">cloud_download</i>
        </Button>
        <Button 
          theme="secondary"
          onClick={onGetUserDownload}
          title={selected.length ? "Export selected user names" : "Export user names"}
          >
          <i className="material-icons mr-1">recent_actors</i>
        </Button>        
        <p
          className="TableFilter-count"
          >{
          selected.length ?
            selected.length + " selected of "+(data.length)+" results"
            :  (data.length)+" results"
        }</p>
      </div>
      <div className="TableFilter-search">

        {
          !props.disable || props.disable.indexOf("filter-status") === -1 ?      
            <FormSelect
              onChange={onFilterStatus}
              defaultValue={props.filterStatusDefault ? props.filterStatusDefault : ""}
              >
              <option value="" disabled>Filter by status</option>
              <option value="">Not Deleted</option>
              {
                !props.filterStatusDefault ? 
                  <option value="">Any</option>
                  : null
              }                    
              {Statuses.map( (status) => {
                if(availableFilterStatuses.indexOf(status.value) > -1){
                  return (
                    <option
                      value={status.value}
                      key={status.value}
                      >{status.label}</option>
                    )
                }
                else{
                  return null;
                }
              })}
            </FormSelect>
            : null
        }
        {
          !props.disable || props.disable.indexOf("filter-name") === -1 ?
            <FormInput 
              placeholder="Search by name"
              onChange={onFilterName}
              onKeyDown={submitFilterName}
              />
            : null
        }
      </div>
      {
        isExporting || exportLink ? 
          <Modal open={true} toggle={setIsExporting}>
            <ModalBody>
              {
                exportLink ?
                  <p>Download</p>
                  : 
                  <LoadingAnimation
                    text={"Making your export"}
                    />                  
              }
            </ModalBody>
          </Modal>
          : null
      }
    </form>
    );
}