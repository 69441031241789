// import React from 'react';
// import * as ImagePicker from 'expo-image-picker';
// import * as ImageManipulator from 'expo-image-manipulator';
import { GetPhotoUrl } from './zuriapi';

// export default function addProfilePhoto(profileId){
//   return ImagePicker.getCameraRollPermissionsAsync()
//     .then(handlePermissions)
//     .then(launchPicker)
//     .then(resizeEditor)
//     .then((file) => upload(profileId, file))
//     .then((key) => saveToProfile(profileId, key))
//     .then(getPhoto)

// }

// /**
//  * [description]
//  * @param  {[type]} res [description]
//  * @return {[type]}     [description]
//  */
// const handlePermissions = async (res) => {
//   let errorMessage = "Can't access your photos"
//   if(res.status !== "denied" ){
//     return true
//   }
//   else if(!res.granted && res.canAskAgain){
//     const { status } = await ImagePicker.getCameraRollPermissionsAsync()
//     if(status !== "denied"){
//       return true;
//     }
//     else{
//       throw new Eror(errorMessage);
//     }
//   }
// }

// /**
//  * [description]
//  * @param  {[type]} canLaunch [description]
//  * @return {[type]}           [description]
//  */
// const launchPicker = (canLaunch) => {
//   return ImagePicker.launchImageLibraryAsync({
//     allowsEditing: true,
//     aspect: [1,1],
//     // quality: 0.5,
//   })
// }

// const resizeEditor = (response) => {
//   if(!response.cancelled){
//     return ImageManipulator.manipulateAsync(
//       response.uri,
//       [{resize: {width: 400, height: 400 }}],
//       {compress: 0.7}
//       )
//   }
//   else {
//     throw new Error("Cancelled")
//   }

// }

// /**
//  * [description]
//  * @param  {[type]} response [description]
//  * @return {[type]}          [description]
//  */
// const upload = (profileId, response) => {
//   if(response.uri){
//     const imageName = response.uri.replace(/^.*[\\\/]/, '');
//     const nameParts = imageName.split(".")
//     const fileType = 'image/'+nameParts[nameParts.length - 1];
//     const access = { level: "public", contentType: fileType };

//     //fail if it isn't a jpg
//     if(fileType !== "image/jpeg" && fileType !== "image/jpg"){
//       throw new Error("Image must be a JPG")
//     }

//     return fetch(response.uri)
//       .then(res => res.blob())
//       .then(blob => {
//         return UploadPhoto({
//           profileId: profileId,
//           fileType: fileType,
//           fileName: imageName,
//           image: blob
//         })
//       });
        
//   }
// }

// const saveToProfile = (profileId, key) => {
//   if(key){

//     return UpdateProfilePhoto(profileId, key)
//       .then((res) => {
//         //process the results maybe?
//         return key;
//       })
//   }
//   else {
//     throw new Error("Couldn't get photo")
//   }
// }

export function getPhoto(key){
  if(key){
    return GetPhotoUrl(key)
  }
  else {
    throw new Error("Couldn't get photo")
  }
}

/**
 * From https://stackoverflow.com/a/16245768
 * @param  {[type]} b64Data     [description]
 * @param  {String} contentType [description]
 * @param  {Number} sliceSize   [description]
 * @return {[type]}             [description]
 */
// const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
//   const byteCharacters = atob(b64Data);
//   const byteArrays = [];

//   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
//     const slice = byteCharacters.slice(offset, offset + sliceSize);

//     const byteNumbers = new Array(slice.length);
//     for (let i = 0; i < slice.length; i++) {
//       byteNumbers[i] = slice.charCodeAt(i);
//     }

//     const byteArray = new Uint8Array(byteNumbers);
//     byteArrays.push(byteArray);
//   }

//   const blob = new Blob(byteArrays, {type: contentType});
//   return blob;
// }