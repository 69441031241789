export default {
  dateFormat: 'MMM D, YYYY',
  datetimeFormat: 'MMM D, YYYY h:mm:ssA',
  timeFormat: 'h:mm:ssA',
  yourProfileTitle: 'Your Profile',
  organizationName: 'VMI Alumni Agencies',
  organizationAddress: `304 Letcher Ave,
PO Box 932.
Lexington, VA
24450`,
  organizationEmail: 'give@vmiaa.org',
  organizationEmailSubject: 'Email from the app',
  organizationEmailText: `Lorem ipsum\nDolor sit amnet`,
  organizationPhone: '1-800-444-1839',
  emailLabel: 'Email',
  phoneLabel: 'Call',
  matchedLabel: "Matched on ",
  loginCountLabel: " logins",
  lastLoginLabel: "Last seen ",
  contactEmailLabel: "Contact email ",
  zuriLabel: 'Developed by Zuri Group',
  zuriUrl: 'zurigroup.com',
  zuriDestination: 'https://zurigroup.com',
  termsLabel: 'Terms of Service',
  termsDestination: 'https://test.com',
  privacyLabel: 'Privacy Policy',
  privacyDestination: 'https://test.com',
  profileNameLabel: 'Name',
  profileEducationLabel: 'Education',
  profileOtherEducationLabel: 'Other Education',
  profileEmailLabel: 'Email',
  profileChapterLabel: 'Chapter Membership',
  profileAddressLabel: 'Address',
  profilePhoneLabel: 'Phone Number',
  profileWorkLabel: 'Work',
  profileDeceased: 'Deceased',
  fieldLabels: {
    crmId: "CRM ID",
    photo: "Photo",
    nameFirst: 'First Name',
    nameLast: 'Last Name',
    nameMiddle: 'Middle Name',
    nameNickname: 'Nickname',
    nameFormerLast: 'Maiden Name',
    nameFull: 'Full Name',
    clientDegree: 'Degree',
    clientMajor: 'Major',
    clientClassyear: 'Class Year',
    deceased: "Deceased",
    otherEducation: {
      sectionTitle: "Other Education",
      degree: 'Degree',
      major: 'Major',
      institution: 'Institution',
      classyear: 'Year'
    },
    emailPrimary: 'Primary Email',
    emailAlternate: 'Alternate Email',
    chapterPrimaryName: 'Primary Chapter',
    chapterSecondaryName: 'Secondary Chapter',
    jobTitle: 'Job Title',
    jobProfession: 'Profession',
    jobEmployer: 'Employer',
    jobIndustry: 'Industry',
    phone: {
      sectionTitle: "Phone",
      Home: 'Home',
      home: 'Home',
      Mobile: 'Mobile',
      mobile: 'Mobile',
      Business: 'Business',
      business: 'Business'
    },
    address:{
      sectionTitle: "Address",
      street: 'Street Address',
      city: 'City',
      state: 'State',
      country: 'Country',
      zip: 'Zip Code',
      home: {
        sectionTitle: 'Home',
        street: 'Street Address',
        unit: 'Unit',
        note: 'Note',
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip Code'
      },
      business: {
        sectionTitle: 'Business',
        street: 'Street Address',
        unit: 'Unit',
        note: 'Note',        
        city: 'City',
        state: 'State',
        country: 'Country',
        zip: 'Zip Code'
      }
    },
    street: 'Street Address',
    unit: 'Unit',
    note: 'Note',
    city: 'City',
    state: 'State',
    country: 'Country',
    zip: 'Zip Code',
    latitude: 'Latitude',
    longitude: 'Longitude',
    approvedDate: 'Approved Date',
    lastLogin: 'Last Login',
    status: 'App Status',
    matched: 'Matched Status',
    "phone.business.number": "Business",
    "phone.home.number": "Home",
    "phone.mobile.number": "Mobile",
    business: "Business",
    home: "Home",    
  },
  notifications:{
    copied: "Copied!",
    noFileSelected: "Please select a file to upload",
    mustSelectUser: "Please select at least one user",
    passwordReset: "Password reset. Code sent to user's email",
    passwordSet: "Password set"
  },
  loadingUser: "Loading User",
  matchRecordTitle: "Match to a Record",
  matchIsMatched: " is matched",
  matchRemove: "Remove Match",
  accessApprove: "Approve",
  accessDefer: "Defer",
  accessDeny: "Deny Access",
  recordTitle: "Constituent Record",
  changeRequestTitle: "Change Requests",
  setPassword: "Set Password",
  resetPassword: "Force Password Change",
  usernameLabel: "Username",
  importTitle: "Import Changes",
  importFileTitle: "Alumni Update File",
  buttonUploadLabel: " Upload",
  importTypeTitle: "Import type",
  importUpdate: "Add or update",
  importDelete: "Delete (Please include only records that you choose to delete from the database)",
  exportTitle: "Exports",
  makeVisibilityOn: "Click to make visible",
  makeVisibilityOff: "Click to make hidden",
  pageAlumniTitle: "Alumni",
  pageUpdatesTitle: "Update Requests",
  pageRequestsTitle: "Access Requests",
  notificationsTitle: "Push Notifications",
  newNotificationTitle: "Send New Notification",
  buttonSendLabel: " Send",
  pagePrivacyTitle: "Privacy Changes",
  buttonNextPage: "Next Page",
  confirmDeleteTitle: "Confirm delete",
  confirmDeletePrompt: "Are you sure you want to delete these users?",
  confirmDeleteActionPrompt: 'Type "confirm" below (no quotation marks).',
  setPasswordPrompt: "Enter a new password.",
  confirmClearPrivacyTitle: "Confirm clear",
  confirmClearPrivacyPrompt: "Are you sure you want to clear these privacy changes?",
  confirmClearChangeRequestTitle: "Confirm clear",
  confirmClearChangeRequestPrompt: "Are you sure you want to clear these requested changes?",
  confirmSendTitle: "Confirm notification send",
  confirmSendPrompt: "Notifications can't be un-sent, are you sure you want to send this notification?",
  confirmSendActionPrompt: "Type \"confirm\" below (no quotation marks).",
  confirmMatch: "Confirm match",
  buttonConfirm: "Confirm",
  buttonCancel: "Cancel",
  buttonSubmit: "Submit",
  buttonClearChanges: "Clear changes"

}