import React, { useEffect, useState } from "react"; //useEffect and useState are required for stats, which are currently disabled
// import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import QuickStat from "./../components/common/QuickStat";
import Overview from "./../components/users/Overview";
// import UsersByDevice from "./../components/blog/UsersByDevice";

import { GetStats } from '../utils/zuriapi';
import { convertRegistrationDate } from '../utils/functions';
import { baseStats, resultToStatMap } from '../utils/dashboard-stats';

/**
 * [description]
 * @param  {[type]} options.smallStats [description]
 * @return {[type]}                    [description]
 */
const Dashboard = ({ smallStats }) => {
  const [ stats, setStats ] = useState(false);

  //load stats from API
  useEffect(() => {
    async function fetchData(){
      const statRequest = await GetStats();
      let result = {};

      if(statRequest){
        result = newStats(statRequest, baseStats);
      }
      else{
        result = {
          loading: false,
          error: "Error: Couldn't get stats"
        }
      }

      setStats(result);
    }

    fetchData();
  }, []);

  return (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Dashboard" className="text-sm-left mb-3" />
    </Row>

    {/* Small Stats Blocks */}
    <Row>
      {stats && stats.data ? 
        stats.data.map((stats, idx) => (
        <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
          <QuickStat
            id={`small-stats-${idx}`}
            variation="1"
            chartData={stats.datasets}
            chartLabels={stats.chartLabels}
            label={stats.label}
            value={stats.value}
            detail={stats.detail}
            increase={stats.increase}
            decrease={stats.decrease}
          />
        </Col>
      ))
      : null }
    </Row>

    <Row>
      {/* List of new requests */}
      <Col lg="6" md="12" sm="12" className="mb-4">
        <Overview
          title="Access Requests"
          columnKeys={["nameFull", "registerDate"]}
          columnTitles={["Name", "Registration Date"]}
          columnLinks={{"nameFull": "profileUrl"}}
          action={{
            label: "View all",
            action: "alumni-requests"
          }}
          filters={{
            status: "PENDING"
            }}
          rowProcess={convertRegistrationDate}
          />
      </Col>

      {/* Suggested updates */}
      <Col lg="6" md="12" sm="12" className="mb-4">
        <Overview
          title="Suggested Updates"
          columnLinks={{"nameFull": "profileUrl"}}
          action={{
            label: "View all",
            action: "alumni-updates"
          }} 
          filters={{
            status: "APPROVED",
            changeRequestTime: "*"
            }}          
          />
      </Col>
    </Row>
  </Container>
)};

export default Dashboard;




/**
 * [description]
 * @param  {[type]} originalText [description]
 * @param  {[type]} inputValue   [description]
 * @return {[type]}              [description]
 */
const replaceNumber = (originalText, inputValue) => {
  let value = parseFloat(inputValue);
  if(typeof value.toLocaleString === "function"){
    value = value.toLocaleString();
  }

  return originalText.replace("#", value.toString());
}

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const newStats = (data, base = {}) => {
  let result = Object.assign({}, base);
  result.loading = false;

  //populate the main stats
  for(let i = 0; i < resultToStatMap.length; i++){
    let key = resultToStatMap[i].key;
    let index = resultToStatMap[i].itemNum;
    
    if(data[key]){
      result.data[index].value = replaceNumber(result.data[index].value, data[key].total);
      result.data[index].datasets[0].data = data[key].series;
    }
  }

  //populate the captions with the total number of records
  if(data.allRecords){
    for(let i = 0; i < 3; i++){
      result.data[i].detail = replaceNumber(result.data[i].detail, data.allRecords.total);
    }
  }

  return result;
}
