import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export function accessRowActions(row){
  return (
    <Fragment>
      <Link
        to={"/user/" + row.id}
        >View</Link> | 
      <Link
        to={"/user/" + row.id}
        >Accept</Link> | 
      <Link
        to={"/user/" + row.id}
        >Defer</Link>
    </Fragment>  
  );
}

export function userRowActions(row){
  return (
    <Fragment>
      <Link
        to={"/user/" + row.id}
        >View</Link>
    </Fragment>  
  );
}

/**
 * [formatText description]
 * @param  {[type]} format      [description]
 * @param  {[type]} mergeFields [description]
 * @return {String}             [description]
 */
export function formatText(format, mergeData, profile){  
  //get the placeholders
  const placeholderRegex = /%([^%]*)%/g; 
  const placeholders = format.match(placeholderRegex);

  let mergeFields = {};
  for(let k = 0; k < mergeData.length; k++){
    mergeFields[mergeData[k]] = profile[mergeData[k]]
  }

  //set placeholders as blanks
  let replacements = {};
  for(let i = 0; i < placeholders.length; i++){
    replacements[placeholders[i]] = "";
  }

  //merge in the merge fields
  const mergeKeys = Object.keys(mergeFields);
  for(let i = 0; i < mergeKeys.length; i++){
    replacements["%"+mergeKeys[i]+"%"] = mergeFields[mergeKeys[i]];
  }
  
  //generate the full string
  let text = format;
  for(let i = 0; i < placeholders.length; i++){
    text = text.replace(placeholders[i], replacements[placeholders[i]]);
  }

  return text;
}

export function joinText(fields, profile){
  const data = fields.map( (field) => {
    return typeof profile[field] === "string" ? profile[field] : "";
  })

  return data.join(", ");
}

/**
 * [makeRowText description]
 * @param  {[type]} text [description]
 * @return {[type]}      [description]
 */
export function makeRowText(text){

  return (
    <p>{text}</p>
    );
}

/**
 * [makeRowTitle description]
 * @param  {[type]} text [description]
 * @return {[type]}      [description]
 */
export function makeRowTitle(text){

  return (
    <h5 className="Profile-PropertyTitle">{text}</h5>
    );
}