import React from 'react';
import { useAppValue } from '../../AppContext';
import './notifications.css';
import Notification from './Notification';

export default function Notifications(){
  const [ {notifications}, dispatch ] = useAppValue();

  return(
    <div className="Notifications">
      {notifications.map( (notification) => (
        <Notification
          {...notification}
          key={notification.id}
          />
        ))}
    </div>
    );
}