export default [
"AA",
"AAS",
"AAS Drafting & Design",
"AB",
"ABD",
"Accounting",
"Accupuncture License",
"AD",
"Adult & Continuing Education",
"AEGD",
"Aeronautical Science",
"AJPME",
"AM",
"AMim",
"AMP",
"AS",
"ASE",
"ASEE",
"Associate",
"Associate Degree",
"Associate Engineer",
"Associate in Engineering",
"Associate in Science",
"Associate in Science in Electronic Engineering",
"Associate in Science in Engineering",
"Associate of Applied Sciences",
"Associates",
"Associates Cert.",
"Associates, Culinary Science",
"B.A.",
"B.A. Accounting",
"B.A. History",
"B.S.",
"B.S. Business Admin",
"B.S. Computer Science",
"B.S. Industrial Arts Ed",
"B.S. Management and Technology",
"B.S. Physics",
"B.S. Psychology",
"BA",
"BA Chemistry",
"BA Disting",
"BA in Science",
"BA With Dist",
"BA*",
"BA**",
"BA-Anthropology",
"BA/BS",
"Bachelor",
"Bachelor of Architecture",
"Bachelor of Arts",
"Bachelor of Business Administration",
"Bachelor Of Chemical Engineering",
"Bachelor of Chemical Science",
"Bachelor of Chemistry",
"Bachelor of Civil Engineering",
"Bachelor of Civil Law",
"Bachelor of Commerce",
"Bachelor of Commercial Science",
"Bachelor of Divinity",
"Bachelor of Economics",
"Bachelor of Education",
"Bachelor of Electrical Engineering",
"Bachelor of Environmental Design",
"Bachelor of Fine Arts",
"Bachelor of Foreign Trade",
"Bachelor of General Studies",
"Bachelor of Geological Engineering",
"Bachelor of Industrial Engineering",
"Bachelor of Laws",
"Bachelor of Liberal Arts",
"Bachelor of Literature",
"Bachelor of Marine Science",
"Bachelor of Mechanical Engr",
"Bachelor of Music",
"Bachelor of Performing Arts",
"Bachelor of Philosophy",
"Bachelor of Sanitary Science",
"Bachelor of Science",
"Bachelor of Science in Agricultural Engineering",
"Bachelor of Science in Business Administration",
"Bachelor of Science in Civil Engineering",
"Bachelor of Science in Electrical Engineering",
"Bachelor of Science in Health Education",
"Bachelor of Science Nursing",
"Bachelor of Urban Studies",
"Bachelors",
"BARCH",
"BAS",
"BBA",
"BC",
"BCE",
"BCHE",
"BCL",
"BCm",
"BCS",
"BCSc",
"BD",
"BECON",
"BEd",
"BEE",
"BET",
"BFA",
"BFT",
"BGE",
"BGS",
"BIE",
"Bil Cert",
"BL",
"BLA",
"BM",
"BMS",
"BMUS",
"BNS",
"BOSM",
"BS",
"BS (ACE) Chemistry",
"BS - Civil Engineering",
"BS Accounting",
"BS Business Finance",
"BS Disting",
"BS in Computer Science",
"BS in Geology",
"BS in Physical Education",
"BS With Dist",
"BS*",
"BS**",
"BS/BS",
"BS/MBA",
"BSAE",
"BSBA",
"BSCE",
"BSChE",
"BSCJ",
"BSCS",
"BSE",
"BSEd",
"BSEE",
"BSH",
"BSIE",
"BSME",
"BSN",
"BSS",
"BSSDA",
"Bureau Highway Safety Cert.",
"BUS",
"Business Administration",
"Business Information Technology",
"Business Management",
"Business Management/Econ",
"CAGS",
"CAS",
"CBC",
"CCIM",
"CE",
"Cert",
"Cert.",
"Cert., CIO",
"Cert., Health",
"Cert., Highway Traffic",
"Certificate International Business Management",
"Certified Brewmasters Course",
"CFA",
"CFP",
"CFP Cert.",
"Chartered Financial Cons",
"Chartered Life Underwriter",
"ChFC",
"CIS",
"Civil Engineering",
"CLU",
"CLU/CHFC",
"CMAP",
"Computer Information Systems",
"CPA",
"CPCU",
"CPT Cert.",
"Cybersecurity",
"D.Eng.",
"DA",
"DBA",
"DC",
"DCC",
"DCL",
"DD",
"DDiv",
"DDS",
"DENG",
"Dermatology",
"Dermatology Cert.",
"DH",
"Diploma",
"Diplomate",
"DL",
"DM",
"DMD",
"DME",
"DMIN",
"DMM",
"DNF",
"DNP",
"DO",
"Doct of Music Ministry",
"Doctor of Business Administration",
"Doctor of Chiropody",
"Doctor of Chiropractic",
"Doctor of Civil Law",
"Doctor of Commercial Science",
"Doctor of Dental Medicine",
"Doctor of Dental Science",
"Doctor of Divinity",
"Doctor of Education",
"Doctor of Engineering",
"Doctor of Humane Letters",
"Doctor of Humanities",
"Doctor of Jurisprudence",
"Doctor of Laws",
"Doctor of Literature",
"Doctor of Mechanical Engineering",
"Doctor of Medicine",
"Doctor of Mining Engineerig",
"Doctor of Mining Engineering",
"Doctor of Ministry",
"Doctor of Music",
"Doctor of Optometry",
"Doctor of Osteopathic Medicine",
"Doctor of Pharmacy",
"Doctor of Philosophy",
"Doctor of Podiatric Medicine",
"Doctor of Political Science",
"Doctor of Public Administration",
"Doctor of Public Health",
"Doctor of Science",
"Doctor of Theology",
"Doctor of Veterinary Medicine",
"Doctorate",
"Doctorate, Technical Education",
"DPA",
"DPM",
"DPS",
"DRENGR",
"DRPH",
"DRS",
"DS",
"DSc",
"DVM",
"Economics",
"Ed. S, School Administration & Supervision",
"Ed.D.",
"EdD",
"EdD in Leadership",
"EdM",
"EdS",
"EE",
"EFM",
"EM",
"EMBA",
"EMT Cert.",
"Engineer of Mining",
"ES",
"Exec Masters in Cybersecurity",
"FACS",
"Fellow",
"Fellowship",
"Flight Navigation Cert.",
"Gemologist Cert.",
"General Studies",
"Grad Cert",
"Grad Cert.",
"Grad Certificate Public Admi",
"Grad Disting",
"Grad With Dist",
"Grad. Cert.",
"Graduate",
"Graduate Performance Diploma",
"Graduate Studies",
"Hon. Doctorate",
"Hon. Doctorate, Letters",
"Hon. PhD",
"Honorary Degree",
"Hoover Fellow",
"HS Diploma",
"Information Systems",
"Intern",
"Internship",
"J.D.",
"JD",
"JD/MBA",
"JPME",
"JPME1",
"Juris Doctor",
"LA",
"LAW",
"Law Degree",
"Law Enforcement Degree",
"LB",
"Leadership/Management Cert.",
"LEED",
"LHD",
"License",
"Life Fellow",
"LITTD",
"LL.M.",
"LLB",
"LLB/JD",
"LLD",
"LLM",
"Long-term Care Mgmt. Cert.",
"LRM",
"M. Ed. Vocational & Adult Education",
"M.A.",
"M.Ed.",
"M.Ed.Physical Education",
"M.Eng",
"M.ENG.",
"M.Eng. Nuclear Engineering",
"M.Litt",
"M.S",
"M.S. Aeronautical Engineering",
"M.S. Applied Math",
"M.S. Computer Science",
"M.S. Engineering and Technology Management",
"M.S. Real Estate Investment Analysis",
"M.S.S.",
"M/Ed",
"MA",
"MA - National Resourse Strategy",
"MA - Organizational Leadership",
"MA - Organizational Management",
"MA Counseling",
"MA Global Security Studies",
"MA History",
"MA in School Counseling",
"MA, Natl Secty & Strategic Studies",
"MA-ADM/Supervision",
"MABA",
"MAEd",
"MAIA",
"MAJ",
"MALS",
"MAOM",
"MARCH",
"MAS",
"master",
"Master Applied Physics",
"Master Human Relations",
"Master of ???",
"Master of ????",
"Master of Aeronautical Science",
"Master of Aeronnautical Science",
"Master of Applied Science",
"Master of Architecture",
"Master of Arts",
"Master of Arts (History)",
"Master of Arts ???",
"Master of Arts in Business Administration",
"Master Of Arts In Humanities",
"Master of Arts in Liberal Studies",
"Master of Arts in Teaching",
"Master of Arts in Theology",
"Master of Arts International Affairs",
"Master of Arts, Intelligence Studies",
"Master of Basic Science",
"Master of Business ???",
"Master of Business Administration",
"Master of Chemical Engineering",
"Master of Chemistry",
"Master of Church Music",
"Master of City Planning",
"Master of Civil Engineering",
"Master of Commercial Science",
"Master of Cosmology",
"Master of Dental Surgery",
"Master of Divinity",
"Master of Education",
"Master of Electrical Engineering",
"Master of Engineering",
"Master of Engineering Administration",
"Master of Engineering Physics",
"Master of Enginering",
"Master of English",
"Master of Finance",
"Master of Fine Arts",
"Master of Forestry",
"Master of Government Admin",
"Master of Health Administration",
"Master of Health Care Administration",
"Master of Human Relations",
"Master of Humane Letters",
"Master of Hygiene",
"Master of Industrial",
"Master of Information Management",
"Master of International Management",
"Master of International Relations",
"Master of Landscape Architecture",
"Master of Laws",
"Master of Library Science",
"Master of Management",
"Master of Management Services",
"Master Of Medical Management",
"Master of Metaphysics",
"Master of Military Operational Art and Science",
"Master of Military Studies",
"Master of Municipal Administration",
"Master of Music",
"Master of Naval Engineering",
"Master of Patent Law",
"Master of Pharmacy",
"Master of Philosophy",
"Master of Physical Educating Health",
"Master of Psychology",
"Master Of Public & Intnl Affairs",
"Master of Public Administration",
"Master of Public Health",
"Master Of Public Policy",
"Master Of Public Service",
"Master Of Publiv Policy",
"Master of Regional Planning",
"Master of Religious Education",
"Master of Sacred Theology",
"Master of Science",
"Master of Science (Strategic Intelligence)",
"Master of Science Business Administration",
"Master of Science in Accountancy",
"Master of Science in Business Administration",
"Master of Science in Civil Engineering",
"Master of Science in Commerce",
"Master of Science in Computer Science",
"Master of Science in Education",
"Master of Science in Elctronic Engineering",
"Master of Science in Engineering",
"Master of Science in Industrial Administration",
"Master of Science in Industrial Engineering",
"Master of Science in Journalism",
"Master of Science in Management",
"Master of Science in Mechanical Engineering",
"Master of Science in Nursing Education",
"Master of Science in Public Health",
"Master of Science in Social Work",
"Master of Science in Teaching",
"Master of Science Strategic Intelligence",
"Master of Science, Operations Research",
"Master of Science, Structural Engineering",
"Master of Social Welfare",
"Master of Statistics",
"Master of Studies",
"Master of Taxation",
"Master of Theology",
"Master of Traditional Oriental Medicine",
"Master of Urban & Regional Planning",
"Masters",
"Masters Business Administration",
"Masters Certificate",
"Masters Engineering Management",
"Masters Engineering Mmgt.",
"Masters in Forensic and Legal Psychology",
"Masters Leadership Studies",
"Masters Mgmt. & Leadership",
"Masters Military Studies",
"Masters National Security",
"Masters of Business Administration",
"Masters of Divinity",
"Masters of International Relations",
"Masters of International Security Strategy Studies",
"Masters of Medical Science- Physician Assistant",
"Masters of Organizational Leadership",
"Masters Science - Civil",
"Masters Strategic Security",
"Masters Strategic Studies",
"Masters,  Aeronautical Science",
"Masters, Administrative Sci.",
"Masters, Air Powers Art Sci.",
"Masters, Applied Physics",
"Masters, Behavioral Sciences",
"Masters, Contract Management",
"Masters, Economics",
"Masters, Engineering Mmgt.",
"Masters, Finance",
"Masters, Financial Planning",
"Masters, History",
"Masters, Leadership Studies",
"Masters, Mgmt. & Leadership",
"Masters, Military Science",
"Masters, Military Studies",
"Masters, National Security",
"Masters, Personnel Management",
"Masters, Policy MAnagement",
"Masters, Strategic Security",
"Masters, Strategic Studies",
"Masters-International Security Studies",
"MAT",
"MATH",
"MATS",
"MB",
"MBA",
"MBP",
"MBS",
"MC",
"MCE",
"MCHE",
"MCM",
"MCO",
"MCP",
"MCS",
"MD",
"MDipl",
"MDiv",
"MDS",
"MDV",
"MDY",
"ME",
"ME Nuclear Engineering",
"ME, Biomedical Eng.",
"ME-CE",
"MEA",
"MEd",
"Medical Coding Cert.",
"MEE",
"MEGR",
"MEM",
"MENG",
"MEngr",
"MEquiv",
"MET",
"MF",
"MFA",
"MFLA",
"MGA",
"MH",
"MHA",
"MHCA",
"MHL",
"MHR",
"MHRD",
"MIA",
"MIE",
"MIM",
"MIR",
"MIS",
"MIT Executive Program",
"MKT",
"MLA",
"MLIC",
"MLS",
"MM",
"MMA",
"MMAS",
"MMM",
"MMPS",
"MMS",
"MNE",
"MOS",
"MPA",
"MPH",
"MPHIL",
"MPIA",
"MPL",
"MPM",
"MPP",
"MPPA",
"MPS",
"MRE",
"MRP",
"MS",
"MS - Organizational Leadership",
"MS Aeronautics",
"MS Chemical Engineering",
"MS Civil Engineering",
"MS Computer Science",
"MS Defense and Strategic Studies",
"MS in Administration",
"MS in Computer Science",
"MS in SO/LIC",
"MS International Relations",
"MS ME",
"MS National Resource Strategy",
"MS Project Management",
"MS Project Mgt",
"MS Strategic Studies",
"MS(N)",
"MS, Accounting",
"MS, Engineering Management",
"MS/MBA",
"MSA",
"MSAE",
"MSBA",
"MSC",
"MSCC",
"MSCE",
"MSCS",
"MSDS",
"MSE",
"MSED",
"MSEE",
"MSF",
"MSFS",
"MSIA",
"MSIE",
"MSJ",
"MSM",
"MSM Information System Security",
"MSME",
"MSN",
"MSN/MHA",
"MSNE",
"MSPA",
"MSPH",
"MSS",
"MSSE",
"MSSL",
"MSSM",
"MSSW",
"MST",
"MSTE",
"MSW",
"MTH",
"MTM",
"MTMH",
"MTOM",
"MTS",
"MURP",
"Music Education",
"National Security and Strategic Studies",
"National Security Cert.",
"NSS",
"O.D.",
"Occupational Safety and Health",
"OD",
"OMS",
"Organic Chemistry",
"Orthopedic Surgery Cert.",
"Paramedic Cert.",
"PE",
"Periodontics Cert.",
"Ph.D",
"Ph.D.",
"PHARM",
"Pharm.D.",
"PharmD",
"PhB",
"PhD",
"PhD Civil Engineering",
"PhD, Systems Engineering",
"PhM",
"Physical Education Cert.",
"Physical Therapy",
"PMD",
"PME",
"PMP",
"Political Science",
"Post Baccalaureate Certificate Accounting",
"Post Grad Work",
"Post-Grad Certificate, Strategic Intelligence",
"Postdoctoral",
"Postgraduate Diploma",
"Property Cert.",
"PSC",
"Registered Nurse",
"Residency",
"RN",
"SCD",
"Secondary Ed. Teacher Cert.",
"Senior Executive Course",
"SEP",
"SM",
"SRC",
"STM",
"Strategic Studies",
"Teachers Cert",
"Teachers Cert.",
"Teaching",
"Teaching Cred",
"TEFL Cert.",
"Terrorism and Counterterrorism",
"ThD",
"ThG",
"ThM",
"War Dip",
"Masters of Strategic Studies",
"Masters Military Science",
"Master of Strategic Studies",
"Master Air Powers Art Sci",
"Master of Business"
]