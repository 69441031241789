export const vmiPool = {
  identityPoolId: '65egsvfai404ofjc60f1knlcf2',
  region: 'us-east-2',
  userPoolId: 'us-east-2_CrxI6AV7v',
  userPoolWebClientId: '65egsvfai404ofjc60f1knlcf2'
}

export const zuriuPool = {
  identityPoolId: '2evqkpbsgr9nn9cbqruun252gg',
  region: 'us-east-2',
  userPoolId: 'us-east-2_lxw6ss9sR',
  userPoolWebClientId: '2evqkpbsgr9nn9cbqruun252gg'  
}

export const adminPool = {
  identityPoolId: 'us-east-2:9ce1a026-659a-4be0-ba90-ccd3a4bfcd80',
  region: 'us-east-2',
  userPoolId: 'us-east-2_tiUt2jGd1',
  userPoolWebClientId: '6j69jubausgo2grfg1b5cdcstk'
}

export const adminConfig = {
  Auth: adminPool,
  API: {
    endpoints: [
        {
            name: "Development",
            endpoint: "https://sw1q8uxjbh.execute-api.us-east-2.amazonaws.com/development"
        },
        {
            name: "Production",
            endpoint: "https://api.mobilealum.com/v1"
        }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'alumni-uploads',
      region: 'us-east-2'
    }
  }
}