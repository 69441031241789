import React from 'react';
import './LoadingAnimation.css';
/*Animation borrowed from https://stephanwagner.me/only-css-loading-spinner*/

export default function LoadingAnimation(props){

  const style = {
    width: props.size,
    height: props.size,
    marginLeft: -0.5 * props.size
  }

  return (
    <div
      className="loading"
      >
        <LoadingSpinner style={style} />
        <p
          className="text-lg text-muted"
          >{props.text}</p>
    </div>
  );  
}

export function LoadingSpinner(props){
  const classes = ["loading-animation"];
  if(props.size){
    classes.push("loading-animation--"+props.size);
  }

  return(
    <aside
      className={classes.join(" ")} 
      {...props}
      />
    )
}

export function LoadingAnimationPage(props){
  return(
    <div
      className="loadingPage"
      >
      <LoadingAnimation
        {...props}
        size={80}
        />
    </div>
    )
}

LoadingAnimation.defaultProps = {
  size: 20,
  text: "Loading"
}