import * as React from 'react';
import { Copy } from '../../utils/config';
// import ProfileRow from './ProfileRow';
import ProfileProperty from './ProfileProperty'

export default function ProfileGroup(props){
  const profile = props.profile;
  // let definedIsLast = typeof props.isLast === "boolean" ? props.isLast : null;
  let userId = props.userId ? props.userId : profile.id;

  // let sectionHasTitle = false;
  let content = [];

  let fieldGroup = props.group.editFields ? props.group.editFields : props.group.fields;



  //for each field in the profileGroup
  for(let i = 0; i < fieldGroup.length; i++){
    const group = props.group;
    const fields = fieldGroup[i];
    const baseKey = props.baseKey + "-" + i;

    let titleText = group.dataLabel && props.profile[group.dataLabel] ? props.profile[group.dataLabel] : false;
    let title = titleText ? 
      titleText : Copy.fieldLabels[fields] ?
        Copy.fieldLabels[fields] : group.label ? 
          Copy[group.label] : false;

    //is this a field with multiple elements?
    if(fields && typeof fields.push === "function"){
      //iterate over the fields
      for(let j = 0; j < fields.length; j++){
        let visible = true;
        let visibilityName = null;

        if(
          props.parent
          && props.group.dataLabel
          && props.profile[props.group.dataLabel]
          ){
          visibilityName = [props.parent, props.profile[props.group.dataLabel], fields[j]].join(".");

          visible = profile.privateFields ?
            profile.privateFields.indexOf(visibilityName) === -1
            : true;
        }
        else{
          visibilityName = fields[j];

          visible = profile.privateFields ? 
            profile.privateFields.indexOf(fields[j]) === -1
            : true
        }

        let subTitle = Copy.fieldLabels[fields[j]] ? Copy.fieldLabels[fields[j]] 
          : Copy.fieldLabels[visibilityName] ? Copy.fieldLabels[visibilityName]
            : title;

        if(profile[fields[j]]){
          content.push(
            <ProfileProperty
              title={subTitle}
              userId={userId}
              name={fields[j]}
              value={profile[fields[j]]}
              key={baseKey + "-" + i + "-" + j}
              visible={visible}
              visibilityName={visibilityName}
              />
            );          
        }
      }
    }
    //no, is this a field that needs to be iterated over?
    else if(profile[fields] && typeof profile[fields].push === "function"){
      for(let j = 0; j < profile[fields].length; j++){
        const fieldList = group.childFields ? group.childFields : Object.keys(profile[fields][j]);

        let parent = fieldGroup.length && fieldGroup.length === 1 ?
          fieldGroup[0] : null; 

        if(
          fieldList.length > 1 
          && profile
          && profile[fields]
          && profile[fields][j]
          && profile[fields][j][group.childLabel]
          ){
          let sectionTitle = Copy.fieldLabels[profile[fields][j][group.childLabel]] ?
            Copy.fieldLabels[profile[fields][j][group.childLabel]] : profile[fields][j][group.childLabel];

          content.push(
            <h5
              className="Profile-SectionTitle"
              key={baseKey+"-"+j+"-sectionTitle"}
              >{sectionTitle}</h5>
            )
        }

        let row = ProfileGroup({
          group: {
            dataLabel: group.childLabel ? group.childLabel : null,
            format: group.childFormat ? group.childFormat : null,
            fields: [fieldList]
          },
          profile: {
            ...profile[fields][j],
            privateFields: profile.privateFields
          },
          parent: parent,
          isLast: j + 1 >= profile[fields].length,
          baseKey: baseKey + "-" + j,
          userId: userId
        });

        content = content.concat(row);
      }

      //don't need the main row push, we're adding a row for each element in the walker above
      continue;
    }
    //nope, is a field with a single value? 
    else if(profile[fields]){

      content.push(
        <ProfileProperty
          title={title}
          userId={userId}
          name={fields}
          value={profile[fields]}
          key={baseKey + "-" + i}
          visible={profile.privateFields ? 
            profile.privateFields.indexOf(fields) === -1
            : true
          }
          visibilityName={fields}
          />
        );
    }
    //nope, then it's nothing
    else{
      continue;
    }
  }

  //Are there Edit List fields?
  if(props.group.editList){
    // hasEditable = true;
    let group = props.group;
    let baseKey = props.baseKey;

    for(let i = 0; i < props.group.editList.length; i++){
      let list = group.editList[i];
      let listFields = group.editListFields ? group.editListFields : group.editList[i];
      let listCount = profile[list] ? profile[list].length : 0;

      for(let j = 0; j < listCount; j++){
        if(group.editListLabel){
          //add title
          content.push(
            <div
              key={"editTitleRow-"+group.editListLabel+"-"+j}
              >
              <h5
                className="Profile-SectionTitle"
                key={"editTitle-"+group.editListLabel+"-"+j}>
                {Copy[group.editListLabel] + (listCount > 1 ? " "+ (j+1) : "")}
              </h5>
            </div>);      
        }


        for(let k = 0; k < listFields.length; k++){
          let fieldIndex = [list,j,listFields[k]].join(".");
          let value = profile[list][j][listFields[k]];
          let title = Copy.fieldLabels[list][listFields[k]];

          content.push(
            <ProfileProperty
              userId={userId}
              title={title}
              name={listFields[k]}
              key={[baseKey, i, j, k].join("-")}
              value={value}
              visibilityName={fieldIndex}
              visible={profile.privateFields ? profile.privateFields.indexOf(fieldIndex) === -1 : true}
              />
            );
        }
      }

    }
  }

  //prepend the title if we actually have a property here
  if(content.length && props.group.label && Copy[props.group.label]){
    content.unshift(
      <h4
        className="Profile-GroupTitle"
        key={ props.baseKey + "gtitle" }
        >{Copy[props.group.label]}</h4>
      );
  }

  return content; 
}