import React, { Fragment } from 'react';
import { 
  FormCheckbox 
} from "shards-react";
import { useTableValue } from './TableContext';

export default function ContextualTableBody(props){

  const [ { selected }, dispatch ] = useTableValue();
  const indexKey = props.rowIndexKey ? props.rowIndexKey : "id";
  const hasRowActions = typeof props.rowActions !== "undefined";

  /**
   * [toggleSelected description]
   * @param  {Boolean} isSelected [description]
   * @param  {[type]}  row        [description]
   * @return {[type]}             [description]
   */
  function toggleSelected(isSelected, row){
    const rowId = row[indexKey];
    const type = isSelected ? "removeRow" : "addRow";

    dispatch({
      type: type,
      rowId: rowId             
      })
  }

  /**
   * [arrayToColumns description]
   * @param  {[type]} row      [description]
   * @param  {[type]} rowIndex [description]
   * @return {[type]}          [description]
   */
  function arrayToColumns(row, rowIndex){
    return row.map((column, colIndex) => (
      <td
        className={cellClass()}
        key={props.tableId + "-" + rowIndex + "-" + colIndex}
        >{column}</td>
      ))    
  }

  /**
   * [keysToColumns description]
   * @param  {[type]} keys     [description]
   * @param  {[type]} row      [description]
   * @param  {[type]} rowIndex [description]
   * @return {[type]}          [description]
   */
  function keysToColumns(keys, row, rowIndex){
    return keys.map((key, keyIndex) => (
      <td
        className={cellClass()}
        key={props.tableId + "-" + rowIndex + "-" + keyIndex}
        >{row[key]}</td>        
      ))
  }

  function cellClass(){
    return "border-top" + (hasRowActions ? " pb-0" : ""); 
  }

  /**
   * render
   */
  return(
    <Fragment>
    {
      props.data && props.data.map ? 
        props.data.map( (row, rowIndex) => {
          const isSelected = selected.indexOf(row[indexKey]) > -1;

          return (
            <tbody
              className="table-row"
              key={props.tableId + "-" + rowIndex + "-body"}
              >
              <tr
                key={props.tableId + "-" + rowIndex}
                >
                {props.addCheckboxes ? 
                  <td className={cellClass()}>
                    <FormCheckbox 
                      onChange={() => {toggleSelected(isSelected, row)}}
                      checked={isSelected}
                      />
                  </td>
                  : <Fragment />
                }                  
                {props.columnKeys ? 
                  keysToColumns(props.columnKeys, row, rowIndex)
                  : arrayToColumns(row, rowIndex)
                }
              </tr>
              {
                !hasRowActions ?
                  <Fragment />
                  : <tr
                    className="table-row-actions"
                    key={props.tableId + "-" + rowIndex + "-actions"}
                    >
                    <td
                      className="border-0 pt-0 pb-1 small"
                      >&nbsp;</td>
                    <td 
                      colSpan="5"
                      className="border-0 pt-0 pb-1 small"
                      >
                      {props.rowActions(row)}
                    </td>
                  </tr>
              }
            </tbody>            
            )
        })
        : <Fragment />
    }
    </Fragment>
  )
}