const baseStats = {
  loading: true,
  data: [
    {
      label: "Total Users",
      value: "#",
      detail: "of # records",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Total Matched Users",
      value: "#",
      detail: "of # records",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(23,198,113,0.1)",
          borderColor: "rgb(23,198,113)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Adoption",
      value: "#%",
      detail: "of # records",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "New Users",
      value: "#",
      detail: "new users joined the app",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    },
    {
      label: "Suggested Updates",
      value: "#",
      detail: "updates were requested",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgb(0,123,255,0.1)",
          borderColor: "rgb(0,123,255)",
          data: [0, 0, 0, 0, 0, 0, 0]
        }
      ]
    }
  ]
}

const resultToStatMap = [
  {key: 'adoption', itemNum: 2},
  {key: 'allUsers', itemNum: 0},
  {key: 'matched', itemNum: 1},
  {key: 'newUsers', itemNum: 3},
  {key: 'updateRequests', itemNum: 4}
]

export {baseStats, resultToStatMap}