import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Card,
  CardBody,
  Button,
  FormRadio,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput
  } from "shards-react";
import FileUpload from '../components/common/FileUpload';
import PageTitle from "../components/common/PageTitle";
import Table from "../components/common/Table";
import { LoadingSpinner } from '../components/common/LoadingAnimation';
import Copy from '../utils/config/Copy';
import Config from '../utils/config/Config';
import { useAppValue } from '../AppContext';
import { UploadUpdatefile, GetUpdatefiles, GetUpdatefileUrl } from '../utils/zuriapi';
import dayjs from 'dayjs';

const Import = () => {
  const uploadTypes = Config.uploadTypes;
  const [ uploadType, setUploadType ] = useState(uploadTypes[0]);
  const [ file, setFile ] = useState(0);
  const [ uploadList, setUploadList ] = useState([]);
  const [ isUpdating, setIsUpdating ] = useState(true);
  const [ confirmedDelete, setConfirmedDelete ] = useState(false);
  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false);
  const appValue = useAppValue();
  const dispatch = appValue[1];

  /**
   * Onload, get all uploads
   */
  useEffect(() => {
    getUploads()

    setInterval(getUploads, 60000);
  }, []);

  /**
   * If there's an upload that's still running (status=importing) poll for updates
   * @param  {[type]} ( [description]
   * @return {[type]}   [description]
   */
  // useEffect(() => {
  //   let uploading = uploadList.filter((item) => item.status === "importing");
  //   if(uploading.length){
  //     for(let i = 0; i < uploading.length; i++){
  //       let delay = 
  //     }
  //   }
  // }, [uploadList])

  /**
   * [description]
   * @return {[type]} [description]
   */
  const getUploads = () => {
    GetUpdatefiles()
      .then(res => {
        if(res.data.items && res.data.items.length){
          //make date strings readable
          res.data.items.map((item) => {
            item.uploaddate = dayjs(item.uploaddate).format(Copy.datetimeFormat);
            return item;
          })

          setUploadList(res.data.items)          
        }
        setIsUpdating(false);
      })
      .catch(error => {
        setIsUpdating(false);
      })    
  }

  /**
   * [description]
   * @param  {[type]} event [description]
   * @param  {[type]} type  [description]
   * @param  {[type]} file  [description]
   * @return {[type]}       [description]
   */
  const maybeDoUpload = async(event, type, file) => {
    event.preventDefault();

    if(type === "importDelete" && confirmedDelete === false){
      setShowConfirmDelete(true);

      throw new Error("Please confirm");
    }
    else {
      if(showConfirmDelete){
        setShowConfirmDelete(false);
      }

      return doUpload(type, file)
    }
  }

  /**
   * [description]
   * @param  {[type]} event [description]
   * @param  {[type]} type  [description]
   * @param  {[type]} file  [description]
   * @return {[type]}       [description]
   */
  const doUpload = async (type, file) => {
    if(file){
      return UploadUpdatefile(type, file);
    }
    else{
      throw new Error(Copy.notifications.noFileSelected)
    }
  }

  const refreshUploads = async(res) => {
    setFile(null);
    setIsUpdating(true);
    setTimeout(getUploads, 10000);
    }

  return (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title={Copy.importTitle} className="text-sm-left" />
    </Row>

    <Card
      className="mb-2"
      >
      <CardBody>
        <form
          onSubmit={ (e) => {
              maybeDoUpload(e, uploadType, file)
                .then(refreshUploads)
                .catch((err) => {
                  dispatch({
                    type: 'addNotification',
                    content: err.message
                  })
                })
              }
            }
          >
          <strong className="text-muted d-block mb-2">{Copy.importFileTitle}</strong>          
          <FileUpload 
            value={file}
            onChange={( file ) => {
              setFile(file);
            }}
            />
          <div>
            <strong className="text-muted d-block mb-2">{Copy.importTypeTitle}</strong>
            {uploadTypes.map( (type) => (
              <FormRadio
                name={type}
                checked={type === uploadType}
                onChange={() => setUploadType(type)}
                key={type}
                >{Copy[type]}</FormRadio>
              ))}
          </div>

          <Button 
            theme="primary"
            >{
              isUpdating ?
                <LoadingSpinner size="small" />
                : <i className="material-icons mr-1">cloud_upload</i>
            }{Copy.buttonUploadLabel}</Button>
        </form>
      </CardBody>
    </Card>
    {
      uploadList.length ? 
        uploadsTable(uploadList, getUploads)
        : React.Fragment      
    }
    <Modal
      open={showConfirmDelete}
      toggle={() => setShowConfirmDelete(!showConfirmDelete)}
      >
      <ModalHeader>{Copy.confirmDeleteTitle}</ModalHeader>
      <ModalBody>
        <p>{Copy.confirmDeletePrompt}</p>
        <div>
          <label>{Copy.confirmDeleteActionPrompt}</label>
          <FormInput
            onChange={(e) => {
              if(e.target.value === "confirm"){
                setConfirmedDelete(true);
              }
            }}
            />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em"
          }}
          >
          <Button
            theme="danger"
            outline={!confirmedDelete}
            disabled={!confirmedDelete}
            onClick={(e) => 
              maybeDoUpload(e, uploadType, file)
                .then(refreshUploads)
                .catch((err) => {
                  dispatch({
                    type: 'addNotification',
                    content: err.message
                  })
                })              
            }
            >{Copy.buttonConfirm}</Button>
          <Button
            theme="secondary"
            outline
            onClick={() => { setShowConfirmDelete(false)}}
            >{Copy.buttonCancel}</Button>
        </div>
      </ModalBody>
    </Modal>
  </Container>
  )


};

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const uploadsTable = ( data, refreshAction ) => {  
  const refreshButton = (
    <Button
      key="uploadsTable-refresh"
      className="button-refresh"
      onClick={(e) => refreshAction()}
      >Refresh</Button>
    );

  return Table({
    title: "Uploads",
    note: ["Showing 10 most recent imports", refreshButton],
    data: data,
    columnKeys: ["type", "status", "filename", "uploaddate", "successCount", "failureCount"],
    columnTitles: ["Type", "Status", "File Name" ,"Upload Date", "Imported", "Errors"],
    rowActions: (row) => (
        <Button
          onClick={(e) => {
            e.preventDefault();
            makeDownloadLink(decodeURIComponent(row.filename))
          }}
          >Download</Button>
      )
    });
}

/**
 * [description]
 * @param  {[type]} key [description]
 * @return {[type]}     [description]
 */
const makeDownloadLink = (key) => {
  GetUpdatefileUrl(key)
    .then(res => window.location = res)
    .catch(err => console.log("e", err));
} 

export default Import;