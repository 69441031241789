import React, { useState, useEffect, Fragment } from "react";
import { 
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button
  } from "shards-react";
import { useHistory } from 'react-router-dom';
import PageTitle from "../components/common/PageTitle";
import LoadingAnimation, {LoadingAnimationPage } from "../components/common/LoadingAnimation";
import Profile from '../components/users/Profile';
import UserSearch from '../components/users/UserSearch';
import SetPasswordModal from '../components/users/SetPasswordModal';
import { makeRowTitle, makeRowText } from '../components/users/functions';
import { 
  RemoveAlumChangeRequest,
  setAlumsStatus,
  ResetPassword,
  UnmatchAlum
  } from '../utils/zuriapi';
import { getPhoto } from '../utils/addProfilePhoto';
import { useAppValue } from '../AppContext';
import dayjs from 'dayjs';

import { GetAlum } from '../utils/zuriapi';

// import { sampleProfile } from "../utils/sampleData";
import { Copy } from '../utils/config';

export default function User(props){
  const history = useHistory();
  const [ userId, setUserId ] = useState(props.match.params.userId);
  const [ user, setUser ] = useState(false);
  const [ showSetPassword, setShowSetPassword ] = useState(false);
  const [ context, dispatch ] = useAppValue();
  const [ photoUrl, setPhotoUrl ] = useState(null);

  //get alum data
  useEffect(() => {
    async function fetchData(){
      const alum = await GetAlum(userId);
      let result = {};

      if(alum.data){
        result = alum.data;
      }
      else{
        history.goBack()
      }

      setUser(result);
    }

    fetchData();
  }, [userId, history]);

  //get photo
  useEffect(() => {
    if(user && user.photo){
      getPhoto(user.photo)
        .then(res => setPhotoUrl(res));
    }
  }, [ user ])

  /**
   * Render Change Request items
   * @param  {[type]} field [description]
   * @param  {[type]} index [description]
   * @return {[type]}       [description]
   */
  const changeFieldItem = (field, index) => {
    let fieldNameParts = field.split(".");
    let title = [];

    let titleWalker = Copy.fieldLabels;
    for(let i = 1; i <= fieldNameParts.length; i++){
      //ignore numeric parts of the title path
      if(parseInt(fieldNameParts[(i - 1)]) == fieldNameParts[(i - 1)]){
        if(fieldNameParts[(i - 1)] !== "0"){
          title.push(fieldNameParts[(i - 1)]);
        }
        continue;
      }

      titleWalker = titleWalker[fieldNameParts[(i - 1)]]

      if(!titleWalker){
        break;
      }

      if(i < fieldNameParts.length){
        if(titleWalker.sectionTitle){
          title.push(titleWalker.sectionTitle);          
        }
        else if(i + 1 === fieldNameParts.length && fieldNameParts[i] === "number"){
          title.push(titleWalker);
        }

      }
      else{
        title.push(titleWalker)
      }      
    }

    let fieldName = title.join(" — ");

    return(
      <React.Fragment
        key={"change-"+index}
        >
        <dt>{fieldName}</dt>
        <dd>{user.changeRequest[field]}</dd>
      </React.Fragment>
      )
    }

  /**
   * [description]
   * @return {[type]} [description]
   */
  const clearChanges = () => {
    RemoveAlumChangeRequest(userId)
      .then(() => {

        setUser({
          ...user,
          changeRequest: null,
          changeRequestTime: null
        });
      })
      .catch((err) => {
        dispatch({
          type: 'addNotification',
          content: err.message
        })
      })
  }

  /**
   * [description]
   * @param  {[type]} newStatus [description]
   * @return {[type]}           [description]
   */
  const setStatus = (newStatus) => {
    setAlumsStatus([userId], newStatus)
      .then((res) => {
        setUser({
          ...user,
          status: newStatus
        })
      })
      .catch((err) => {
        dispatch({
          type: 'addNotification',
          content: err.message
        })
      })
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  const resetPassword = () => {
    ResetPassword( user.username )
      .then((res) => {
        if(res.CodeDeliveryDetails){
          dispatch({
            type: 'addNotification',
            content: Copy.notifications.passwordReset
          })          
        }
      })
      .catch((err) => {
        let message = err.message;
        if(err.response && err.response.data && err.response.data.error && err.response.data.error.message){
          message = err.response.data.error.message;
        }

        dispatch({
          type: 'addNotification',
          content: message
        })

      })
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  const removeMatch = () => {
    UnmatchAlum( userId )
      .then(( res ) => {
        if(res.data.status === "UNCLAIMED"){
          setUser({
            ...user,
            cognitoId: "",
            matched: "FALSE",
            status: "UNCLAIMED"
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: 'addNotification',
          content: err.message
        })        
      })
  }


  /**
   * Main render
   */
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {
        !user ?
          <LoadingAnimationPage
            text={Copy.loadingUser + " " + userId}
            />
          :
          <Fragment>
            <Row noGutters className="page-header py-4">
              {
                user.photo ?
                  photoUrl === null ?
                    <LoadingAnimation />
                    : 
                    <img
                      className="border border-white rounded-circle Profile-photo" 
                      src={photoUrl}
                      alt=""
                      />
                  : null
              }
              <Col>
                <PageTitle 
                  sm="4" 
                  title={user.nameFull}
                  badge={user.status}
                  className="text-sm-left align-items-center d-flex pl-2" 
                  />
                {user.crmId || user.clientClassyear ? 
                  <p className="pl-2 font-weight-lighter">
                    {
                    user.crmId ?
                      <span className="Profile-meta">CRM ID: {user.crmId}</span>
                      :null
                      }
                    {
                    user.clientClassyear ?
                      <span className="Profile-meta">Class of {user.clientClassyear}</span>
                      :null
                      }                      
                  </p>
                  : null
                  }
              </Col>
            </Row>
            <Row
              className="flex-row-reverse"
              >
              <Col
                sm="6"
                >
                {
                  user.matched === "TRUE" ?
                  <Card
                    className="mb-4"
                    >
                    <CardTitle
                      className="px-4 py-2"
                      >{user.nameFirst}{Copy.matchIsMatched}</CardTitle>
                    <CardBody
                      className="px-4 pt-2 pb-4"
                      >
                      <div className="Profile-Property">
                        {makeRowTitle(Copy.usernameLabel)}
                        {makeRowText(user.username)}
                      </div>
                      { user.matchDate ? 
                        <div className="Profile-Property">
                          {makeRowTitle(Copy.matchedLabel)}
                          {makeRowText(dayjs(user.matchDate).format(Copy.datetimeFormat))}
                        </div>
                        : null
                      }
                      { user.loginCount ? 
                        <div className="Profile-Property">
                          {makeRowTitle(Copy.loginCountLabel)}
                          {makeRowText(user.loginCount)}
                        </div>
                        : null
                      }
                      { user.lastLogin ? 
                        <div className="Profile-Property">
                          {makeRowTitle(Copy.lastLoginLabel)}
                          {makeRowText(dayjs(user.lastLogin).format(Copy.datetimeFormat))}
                        </div>
                        : null
                      }
                      {
                        user.emailContact ?
                        <div className="Profile-Property">
                          {makeRowTitle(Copy.contactEmailLabel)}
                          {makeRowText(user.emailContact)}
                        </div>
                        : null
                      }
                      <Button
                        theme="danger"
                        onClick={() => removeMatch()}
                        >{Copy.matchRemove}</Button>
                    </CardBody>
                  </Card>
                  :
                  user.status === "PENDING" ?
                    <Card className="mb-4">
                      <CardTitle
                        className="px-4 py-2"
                        >{Copy.matchRecordTitle}</CardTitle>
                      <CardBody
                        className="px-4 pt-2 pb-4"
                        >
                        <UserSearch 
                          currentUser={userId}
                          updateUser={(newUserId) => {
                            setUserId(newUserId);
                          }}
                          />
                      </CardBody>
                    </Card>
                    : <React.Fragment />
                }
                {
                  user.changeRequest ?
                  <Card
                    className="mb-4"
                    >
                    <CardTitle
                      className="px-4 py-2"
                      >{Copy.changeRequestTitle}</CardTitle>                    
                    <CardBody>
                      <dl>
                        {
                          Object.keys(user.changeRequest).sort().map(changeFieldItem)
                        }
                      </dl>
                      <Button
                        onClick={clearChanges}
                        >{Copy.buttonClearChanges}</Button>
                    </CardBody>
                  </Card>
                  :
                  <React.Fragment />
                }

                {user.matched !== "TRUE" && user.emailPrimary ?
                  <Card
                    className="mb-4"
                    >
                    <CardBody>
                      <p>Contact this person at <a href="mailto:{user.emailContact ? user.emailContact : user.emailPrimary}">{user.emailContact ? user.emailContact : user.emailPrimary}</a> if you don't have enough information to confirm their identity</p>
                    </CardBody>
                  </Card>
                  : ""
                }
                <Row
                  className="mb-4 mx-0"
                  >
                  {
                    user.status === "PENDING" ?
                      <Button
                        theme="warning"
                        className="mr-2 mb-2"
                        onClick={() => setStatus("DEFERRED")}
                        >{Copy.accessDefer}</Button>
                      : null
                  }
                  {
                    user.status !== "DENIED" ?
                      <Button
                        theme="danger"
                        className="mr-2 mb-2"
                        onClick={() => setStatus("DENIED")}
                        >{Copy.accessDeny}</Button>
                      : null                    
                  }
                  {
                    user.username ? 
                      <React.Fragment>
                        <Button
                          theme="info"
                          className="mr-2 mb-2"
                          outline={true}
                          onClick={() => resetPassword()}
                          >{Copy.resetPassword}</Button>
                        <Button
                          theme="info"
                          className="mr-2 mb-2"
                          outline={true}
                          onClick={() => setShowSetPassword(true)}
                          >{Copy.setPassword}</Button>
                      </React.Fragment>
                      : null
                  }
                </Row>
              </Col>
              <Col
                sm="6"
                >
                <Card>
                  <CardTitle
                    className="px-4 py-2"
                    >{Copy.recordTitle}</CardTitle>
                  <CardBody>
                    <Profile
                      profile={user}
                      />
                  </CardBody>
                </Card>
              </Col>
            </Row>
        </Fragment>
      }
      {
        user.username ?
          <SetPasswordModal
            username={user.username}
            show={showSetPassword}
            setShow={(show) => setShowSetPassword(show)}
            title="Set Password"
            />
          : <React.Fragment />
      }
    </Container>
  )
};