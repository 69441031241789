export default {
  profileGroups: [
    {
      label: 'profileDeceased',
      fields: ['deceased']
    },
    {
      label: 'profileNameLabel',
      fields: ['nameFull'],
      editFields: [
        'nameFirst',
        'nameMiddle',
        'nameLast',
        'nameFormerLast'
        ]
    },
    {
      label: 'profileEmailLabel',
      fields: ['emailPrimary', 'emailAlternate']
    },
    {
      label: 'profileChapterLabel',
      fields: ['chapterPrimaryName', 'chapterSecondaryName']
    },
    {
      label: 'profilePhoneLabel',
      fields: ['phone'],
      childLabel: 'type',
      childFields: ['number']
    },
    {
      label: 'profileAddressLabel',
      fields: ['address'],
      childLabel: 'type',
      childFormat: `%street%,\n%city%, %state%,\n%country%`,
      childFields: ["street", "unit", "note", "city", "state", "country", "zip"]
    },
    {
      label: 'profileEducationLabel',
      format: "%clientDegree%. %clientMajor%. Class of %clientClassyear%.",
      childFormat: "%degree%. %institution%. Class of %classyear%.",
      fields: [
        ['clientDegree', 'clientMajor', 'clientClassyear'],
        'otherEducation'
        ],
      editFields: [
        'clientDegree',
        'clientMajor',
        'clientClassyear',
        'clientInstitution',
        ],
      editList: [ 'otherEducation' ],
      editListLabel: 'profileOtherEducationLabel',
      editListCount: 5,
      editListFields: [ 'degree', 'institution', 'classyear' ]      
    },
    {
      label: 'profileWorkLabel',
      format: "%jobTitle% at %jobEmployer%. (%jobIndustry%)",
      fields: [['jobTitle', 'jobEmployer', 'jobIndustry', 'jobProfession']]
    }
  ],
  uploadTypes: ["importUpdate", "importDelete"]
}