import React, {useState} from 'react';
import {
  addAlumPrivateField,
  removeAlumPrivateField
} from '../../utils/zuriapi';
import { Copy } from '../../utils/config';

export default function ProfileProperty(props){
  let classes = ["Profile-PropertyValue"];
  if(props.isLast){
    classes.push("is-last");
  }

  const [visible, setVisible] = useState(props.visible) 

  if(!props.value){
    return (<React.Fragment />);
  }
  else{
    return (
      <div className="Profile-Property"
        title={props.visible ? Copy['makeVisibilityOff'] : Copy['makeVisibilityOn']}
        >
         <h5
           className="Profile-PropertyTitle"
           >{props.title}</h5>
        <div
          id={props.name}
          className={classes.join(" ")}
          >
          <i 
            className="material-icons mr-1"
            onClick={() => {
              visible ?
                addAlumPrivateField(props.userId, props.visibilityName).then(
                  (res) => {if(res.data && res.data.privateFields){setVisible(!visible)}}
                  ).catch()
                : removeAlumPrivateField(props.userId, props.visibilityName).then(
                  (res) => {setVisible(!visible)}
                  ).catch()        
            }}            
            >{visible ? "visibility" : "visibility_off"}</i>
          {props.value}
        </div>
      </div>
      );
  }
}