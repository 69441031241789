import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Card,
  CardBody,
  Button,
  FormRadio,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput,
  FormGroup,
  FormTextarea
  } from "shards-react";
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import Table from "../components/common/Table";
import { LoadingSpinner } from '../components/common/LoadingAnimation';
import Copy from '../utils/config/Copy';
import Config from '../utils/config/Config';
import { useAppValue } from '../AppContext';
import { GetNotificationRequests, CreateNotificationRequest, GetOption } from '../utils/zuriapi';
import dayjs from 'dayjs';

const defaultValues = {
  name: "",
  title: "",
  body: "",
  classes: [],
  chapters: [],
  operand: "OR",
  action: ""
}

const NewNotification = () => {
  const [ chapterList, setChapterList ] = useState([]);
  const [ isUpdating, setIsUpdating ] = useState(true);
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ formData, setFormData ] = useState(defaultValues);
  const [ confirmedSend, setConfirmedSend ] = useState(false);
  const [ showConfirmSend, setShowConfirmSend ] = useState(false);
  const router = useHistory();
  const appValue = useAppValue();
  const dispatch = appValue[1];

  /**
   * Onload, get all uploads
   */
  useEffect(() => {
    getChapters();

    //setInterval(getUploads, 60000);
  }, []);

  /**
   * [description]
   * @return {[type]} [description]
   */
  const getChapters = () => {
    GetOption('chapterList')
      .then(res => {
        console.log(res);
        if(res.data && res.data.value) {
          setChapterList(res.data.value.sort((a,b) => {
            if(a.label > b.label) {
              return 1;
            } else if(a.label < b.label) {
              return -1;
            } else {
              return 0;
            }
          }))
        }
        setIsUpdating(false);
      })
      .catch(error => {
        setIsUpdating(false);
      })
  }

  const createNotificationRequest = () => {
    let payload = {
      name: formData.name,
      title: formData.title,
      body: formData.body,
      criteria: {
        classes: formData.classes,
        chapters: formData.chapters,
        condition: formData.operand
      },
    }
    if(isUrl(formData.action)) {
      payload.data = {
        action: 'openUrl',
        url: formData.action
      }
    }

    CreateNotificationRequest(payload).then(response => {
      console.log(response);
      router.push('/alumni-notifications');
    });
  }

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData(prevState => {
      return {...prevState, [name]: value}
    });
  }

  const handleOperandChange = (value) => {
    setFormData(prevState => {
      return {...prevState, operand: value}
    });
  }

  const handleSelectChange = (name, selected) => {
    setFormData(prevState => {
      console.log({...prevState, [name]: selected.map(item => (item.value))})
      return {...prevState, [name]: selected.map(item => (item.value))}
    });
  }

  const handlePreviewClick = () => {
    console.log(formData.action, isUrl(formData.action));
    if(isUrl(formData.action)) {
      window.open(formData.action, '_blank', 'noreferrer');
    }
  }

  const isUrl = (testUrl) => {
    return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(testUrl);
  }

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={Copy.newNotificationTitle} className="text-sm-left" />
      </Row>
      <Card
      className="mb-2"
      >
      <CardBody>
        <form
          method="post"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirmSend(true);
          }}
          >
          <h4>Notification Details</h4>
          <FormGroup>
            <label className="d-block" htmlFor="#name">Internal Name</label>
            <FormInput id="#name" name="name" onChange={handleChange} />
            <label className="d-block text-muted" htmlFor="#name">For reference only; not part of the notification</label>
          </FormGroup>
          <FormGroup>
            <label className="d-block" htmlFor="#title">Message Title</label>
            <FormInput id="#title" name="title" onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <label className="d-block" htmlFor="#body">Message Body</label>
            <FormTextarea id="#body" name="body" onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            <label className="d-block" htmlFor="#action">On Tap Action URL (leave blank for no action)</label>
            <FormInput id="#action" name="action" onChange={handleChange} />
            <label className="d-block text-muted" htmlFor="#action">Include the full URL (ex: https://vmi.edu)</label>
          </FormGroup>
          <h4>Preview</h4>
          <Card className="my-4" style={{maxWidth: "400px", cursor: isUrl(formData.action) ? 'pointer' : 'auto'}}>
            <CardBody onClick={handlePreviewClick}>
              <p>{formData.title}<br/>
              {formData.body}</p>
            </CardBody>
          </Card>
          {isUrl(formData.action) && <p className="text-muted">Clicking this notification will take you to {formData.action}</p>}
          <h4>Targeting</h4>
          <FormGroup>
            <label className="d-block" htmlFor="#action">Target Class Years</label>
            <Select
              defaultValue={[]}
              isMulti
              name="classes"
              menuPortalTarget={document.querySelector('body')}
              options={(() => {
                let years = [];
                for(let i=(new Date().getFullYear()); i>=1900; i--) {
                  years.push({value:i.toString(), label: i.toString()});
                }
                return years;
              })()}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selected) => {handleSelectChange('classes', selected)}}
            />
          </FormGroup>
          <FormGroup>
            <label className="d-block" htmlFor="#action">Target Chapters</label>
            <Select
              defaultValue={[]}
              isMulti
              isLoading={isUpdating}
              isDisabled={isUpdating}
              name="chapters"
              menuPortalTarget={document.querySelector('body')}
              options={chapterList.map(item => {
                return {value: item.key, label: item.label};
              })}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(selected) => {handleSelectChange('chapters', selected)}}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="#relation" className="d-block">Criteria Relation</label>
            <FormRadio
              inline
              name="operand"
              defaultChecked={true}        
              checked={formData.operand === 'OR'}
              onChange={() => { handleOperandChange('OR') }}
              value="OR"
              >OR</FormRadio>
              <FormRadio
              inline
              name="operand"
              checked={formData.operand === 'AND'}
              onChange={() => { handleOperandChange('AND') }}
              >AND</FormRadio>
            <label className="text-muted d-block">If the targets should match both one of the class years AND one of the chapters, choose 'AND'.</label>
          </FormGroup>
          
          
          <Button 
            theme="primary"
            disabled={isSubmitting || isUpdating || !formData.name || (!formData.body && !formData.title)}
            >{
              isUpdating ?
                <LoadingSpinner size="small" />
                : <i className="material-icons mr-1">forum</i>
            }{Copy.buttonSendLabel}</Button>
        </form>
      </CardBody>
    </Card>
    <Modal
      open={showConfirmSend}
      toggle={() => setShowConfirmSend(!showConfirmSend)}
      >
      <ModalHeader>{Copy.confirmSendTitle}</ModalHeader>
      <ModalBody>
        <p>{Copy.confirmSendPrompt}</p>
        <div>
          <label>{Copy.confirmSendActionPrompt}</label>
          <FormInput
            onChange={(e) => {
              if(e.target.value === "confirm"){
                setConfirmedSend(true);
              } else {
                setConfirmedSend(false);
              }
            }}
            />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "1em"
          }}
          >
          <Button
            theme="danger"
            outline={!confirmedSend}
            disabled={!confirmedSend}
            onClick={(e) => {
              if(!confirmedSend) return;
              setShowConfirmSend(false);
              setIsSubmitting(true);
              createNotificationRequest();
            }}
            >{Copy.buttonConfirm}</Button>
          <Button
            theme="secondary"
            outline
            onClick={() => { setShowConfirmSend(false)}}
            >{Copy.buttonCancel}</Button>
        </div>
      </ModalBody>
    </Modal>
    </Container>
  );
};


export default NewNotification;