import React, { Fragment, useState } from 'react';
import { 
  Card, 
  CardHeader, 
  CardBody, 
  FormCheckbox 
} from "shards-react";
import { Link } from "react-router-dom";
import ContextualTableBody from './ContextualTableBody';
import { useTableValue } from '../common/TableContext';

export default function ContextualTable(props){

  const tableContext = useTableValue();
  const tableDispatch = tableContext[1];
  const [ isAllSelected, setAllSelected ] = useState(false);
  const tableId = Math.random() * 10000;

  // const sortIcon = sortDirection === "asc" ? "arrow_drop_up" : "arrow_drop_down";
  // const sortIndex = props.columnKeys ? props.columnKeys.indexOf(sort) : props.columnTitles.indexOf(sort);

  /**
   * Takes the index of the column clicked, maps it to the column key, dispatches it to the table
   * If the table was not provided with column keys, uses the column name instead
   * @param {[type]} index [description]
   */
  // function setSort(index){
  //   const newSort = props.columnKeys ? props.columnKeys[index] : props.columnTitles[index];
  //   const newSortDirection = newSort === sort ? 
  //     sortDirection === "asc" ? "desc" : "asc"
  //     : "desc";

  //   tableDispatch({
  //     type: "setSort",
  //     sort: newSort,
  //     sortDirection: newSortDirection
  //   })
  // }

  function toggleAllSelected(){
    let setSelected = !isAllSelected;
    setAllSelected(setSelected);
    
    //check or uncheck visible rows
    let dispatchType = setSelected ? 'addMultipleRows' : 'removeMultipleRows';
    let ids = props.data.map((row) => row.id);

    tableDispatch({
      type: dispatchType,
      selected: ids
    });

  }

  return (
    <Card small className="mb-4">
      {props.title ? 
        <CardHeader className="border-bottom tableCard-header">
          <h6 className="m-0">{props.title}</h6>
          {
            props.action ? 
            <Link
              className="btn btn-primary"
              to={props.action.action}
              >{props.action.label}</Link>
            : <Fragment />
          }
        </CardHeader>
        : <Fragment />
      }
      <CardBody className="p-0 pb-3">
        <table className="table mb-0">
          {
            props.columnTitles ?
              <thead className="bg-light">
                <tr>
                  {props.addCheckboxes ? 
                    <th scope="col" className="border-bottom">
                      <FormCheckbox 
                        onChange={() => {toggleAllSelected()}}
                        checked={isAllSelected}                        
                        />
                    </th>
                    : <Fragment />
                  }
                  {
                    props.columnTitles.map((columnTitle, index) => {
                      return (
                      <th 
                        scope="col"
                        className="border-bottom table-header"
                        key={tableId + "-h-" + index }
                        >{columnTitle}</th>
                      )})
                  }
                </tr>
              </thead>
              : <Fragment />
          }
          <ContextualTableBody
            tableId={tableId}
            data={props.data}
            columnKeys={props.columnKeys}
            rowIndexKey={"id"}
            addCheckboxes={props.addCheckboxes}
            rowActions={props.rowActions}
            />
        </table>
      </CardBody>
    </Card>
    );  
}

ContextualTable.defaultProps = {
  addCheckboxes: false
}