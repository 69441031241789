import React from "react";
import ViewTable from "./ViewTable";

import { TableProvider, TableDefault, TableReducer } from '../components/common/TableContext';

const Alumni = () => {
  return(
    <ViewTable
      title="Alumni"
      filters={{
        nameSearchable: "*"
      }}
      />
  )
};

export default Alumni;
