export default [
  {
    "value": "PENDING",
    "label": "Pending"
  },
  {
    "value": "UNCLAIMED",
    "label": "Unclaimed"
  },  
  {
    "value": "APPROVED",
    "label": "Approved"
  },
  {
    "value": "DENIED",
    "label": "Denied"
  },
  {
    "value": "DEFERRED",
    "label": "Deferred"
  },
  {
    "value": "DELETED",
    "label": "Deleted"
  }
]