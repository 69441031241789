import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "shards-react";
import ContextualTable from "../components/common/ContextualTable";
import { userRowActions } from "../components/users/functions";
import PageTitle from "../components/common/PageTitle";
import TableFilter from "../components/users/TableFilter";
import LoadingAnimation from "../components/common/LoadingAnimation";
import Copy from "../utils/config/Copy";

import { FindAlums } from '../utils/zuriapi';
import { convertRegistrationDate } from '../utils/functions';
import { useTableValue } from '../components/common/TableContext';

const ViewTable = (props) => {

  const { title } = props;
  const [ isLoading, setIsLoading ] = useState(true);
  const [ nextPage, setNextPage ] = useState(null);
  const [ showNextPage, setShowNextPage ] = useState(false);
  const [ { data, filterStatus, filterName }, tableDispatch ] = useTableValue();
  const { 
    availableFilterStatuses,
    availableChangeStatuses,
    filterStatusDefault,
    actions,
    disable,
    exportAction,
    exportProps,
    filters
  } = props;

  let filterProps = { availableFilterStatuses, availableChangeStatuses, filterStatusDefault, actions, disable, exportAction, exportProps, filters };

  const titles = props.titles ? props.titles : [ 'Name', 'Email', 'Join Date', 'Class Year', 'Status' ];
  const columns = props.columns ? props.columns : [ 'nameFull', 'emailPrimary', 'registerDate', 'clientClassyear', 'status' ]

  /**
   * [description]
   * @param  {[type]} extraParams [description]
   * @return {[type]}             [description]
   */
  const fetchData = async (extraParams, append) => {
    let params = { };
    setIsLoading(true);

    //use any default statuses
    let defaultFilters = filterStatusDefault ? {status: filterStatusDefault} : {}

    //use any intrinsic filters
    let propFilters = props.filters ? props.filters : {};

    //use any extra paramters provided
    extraParams = extraParams ? extraParams : {};

    //make the big ol' array of defaults 
    params = Object.assign(params, defaultFilters, propFilters, extraParams);

    // console.log("s", defaultFilters, propFilters, extraParams);

    // if(
    //   Object.keys(params).length === 1 
    //   && params.nameSearchable 
    //   && params.nameSearchable === "*"
    //   ){
    //   params.Limit = 100;
    // }
    // console.log(params);

    //use any user-specified filters
    if(filterName) params.nameSearchable = filterName;
    if(filterStatus) params.status = filterStatus;

    const response = await FindAlums(params);
    let result = {};

    if(response.data){
      result = response.data;
    }
    else{
      console.log("overview err");
    }

    setIsLoading(false);

    result = typeof result.push === "function" ? result : [];

    tableDispatch({
      type: append ? 'appendData' : 'setData',
      data: result
    });

    //start pagination if the query has additional results
    if(response.LastEvaluatedKey){
      setNextPage(response.LastEvaluatedKey);
    }
    else{
      setNextPage(null);
    }    
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  const goNextPage = () => {
    if(nextPage){
      fetchData({
        ExclusiveStartKey: JSON.stringify(nextPage)
      })
    }
  }

  /**
   * [description]
   * @return {[type]} [description]
   */
  const onRefresh = () => {
    fetchData();
  }

  //Fetch on load and when filters change 
  //TODO: apply useCallback to fetchData, probably? 
  //https://reactjs.org/docs/hooks-faq.html#is-it-safe-to-omit-functions-from-the-list-of-dependencies
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let params = {};
    if(filterName){
      params.nameSearchable = filterName
    }
    if(filterStatus){
      params.status = filterStatus
    }

    fetchData(params);
  }, [filterStatus, filterName]);

  //Fetch if there's a nextPage and we don't have enough results yet
  useEffect(() => {
    if(!nextPage){
      setShowNextPage(false);
    }
    else if(data.length < 100 && nextPage){
      setShowNextPage(false);
      fetchData({
        ExclusiveStartKey: JSON.stringify(nextPage)
      }, true)
    }
    else{
      setShowNextPage(true);
    }
  }, [nextPage])

  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle sm="4" title={title} className="text-sm-left" />
        <TableFilter 
          onRefresh={onRefresh}
          { ...filterProps }
          />
      </Row>
      <Row className="Viewtable">
        <Col>
          {
            isLoading ?
              <LoadingAnimation />
              :
              <ContextualTable
                columnTitles={titles}
                columnKeys={columns}
                rowActions={userRowActions}
                data={data.map(convertRegistrationDate)}
                addCheckboxes={true}
                />
          }
          {
            nextPage && showNextPage ?
              <Button
                theme="primary"
                className="mr-2"
                onClick={goNextPage}
                >{Copy.buttonNextPage}</Button>
              : <React.Fragment />
          }
        </Col>
      </Row>      
    </Container>
    );  
}

export default ViewTable;