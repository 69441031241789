import dayjs from 'dayjs';
import Copy from './config/Copy';

export function convertRegistrationDate(row){
  if(row.registerDate){
    let date = dayjs(row.registerDate).format(Copy.dateFormat);
    row.registerDate = date;
  }

  return row;
}