import React, { Fragment } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";

import { Auth } from 'aws-amplify';
import { AppContext } from '../../../../AppContext';
// import { Store } from "../../../../flux";

class UserActions extends React.Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   visible: false,
    //   user: Store.getUserData()
    // };


    this.state = {
      user: {}
    }

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount(){
    const dispatch = this.context[1];

    if(
      Auth 
      && Auth.user
      && Object.entries(this.state.user).length === 0
      ){

      const user = {
        username: Auth.user.username
      };

      dispatch({
        type: "setUser",
        user: user
      })

      this.setState({
        user: user
      })
    }
  }

  logout() {
    Auth.signOut()
      .then( data => console.log("signed out", data))
      .catch( err => console.log("error", err));
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
          {this.state.user ? 
            <Fragment>        
              <img
                className="user-avatar rounded-circle mr-2 float-left"
                src={require("../../../../assets/vmi.png")}
                alt="User Avatar"
              />
              <span className="d-none d-md-inline-block">{this.state.user.username}</span>
              <br /><span className="d-none d-md-inline-block">VMI</span>
            </Fragment>
            : <Fragment />
          }

        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem 
            to="/" 
            className="text-danger"
            onClick={this.logout}
            >
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}

UserActions.contextType = AppContext;

export default UserActions;