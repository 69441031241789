//icons can be chosen from: https://material.io/resources/icons/?style=baseline
export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">dashboard</i>',
      htmlAfter: ""
    },
    {
      title: "Alumni",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/alumni",
    },
    {
      title: "Access Requests",
      htmlBefore: '<i class="material-icons">perm_device_information</i>',
      to: "/alumni-requests",
    },
    {
      title: "Update Requests",
      htmlBefore: '<i class="material-icons">feedback</i>',
      to: "/alumni-updates",
    },
    {
      title: "Privacy Changes",
      htmlBefore: '<i class="material-icons">visibility</i>',
      to: "/alumni-privacy",
    },
    {
      title: "Import",
      htmlBefore: '<i class="material-icons">cloud_upload</i>',
      to: "/alumni-import",
    },
    {
      title: "Export",
      htmlBefore: '<i class="material-icons">cloud_download</i>',
      to: "/alumni-export",
    },
    {
      title: "Push Notifications",
      htmlBefore: '<i class="material-icons">forum</i>',
      to: "/alumni-notifications",
    }
  ];
}
