import degrees from './config/Degrees';
import industries from './config/Industries';
import majors from './config/Majors';
import professions from './config/Professions';
import schools from './config/Schools';
import statuses from './config/Statuses';
import copy from './config/Copy';
import config from './config/Config';

export const Degrees = degrees;
export const Industries = industries;
export const Majors = majors;
export const Professions = professions;
export const Schools = schools;

export const Statuses = statuses;

export const Copy = copy;

export const Config = config;