import { API, Auth, Storage } from 'aws-amplify';
import { vmiPool, zuriuPool } from '../config/aws';
import { Statuses } from './config';
import AWS from "aws-sdk";
const Cognito = new AWS.CognitoIdentityServiceProvider({region: 'us-east-2'});
let apiName = "Development";
let orgId = "zuriu"; //TODO: get orgId from user setting
let alumPool = zuriuPool;

if(window.location.hostname === "admin.mobilealum.com"){
  apiName = "Production";
  orgId = "vmi";
  alumPool = vmiPool;
}


/**
 * [description]
 * @return {[type]} [description]
 */
export const GetStats = async () => {
  let host = "https://admin.mobilealum.com";
  const now = new Date();
  let request = host + "/stats/"+ orgId +".json?time="+now.toDateString().replace(/\s/g,"");

  return fetch(new Request(request), {
    method: "GET",
    mode: 'cors'
  })
  .then((response) => {
    if(response.ok){
      return response.json()
    }
    else {
      return {}
    }
  })
  .catch(err => {
    console.log(err);
  })

  // let path = '/vmi/admin/stats'; 
  // let opts = {
  //     headers: { 
  //       'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
  //       'Content-Type': 'application/json'
  //     },
  //     response: true
  // }

  // return await API.get(apiName, path, opts).then(response => {
  //     // Add your code here
  //     return response;
  // }).catch(error => {
  //     return error;
  // });  

}

/**
 * [description]
 * @return {[type]} [description]
 */
export const FindAlums = async (params) => {
  let path = '/'+orgId+'/admin/alum';

  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      response: true
  }

  /* Parse nameSearchable  */
  const flaggedFields = {
    "Class": "clientClassyear",
    "Major": "clientMajor",
    "Degree": "clientDegree",
    "Industry": "jobIndustry",
    "Company": "jobEmployer",
    "ID": "id"
  }

  if(params){
    //apply some special handling to the nameSearchable field
    if(params.nameSearchable){
      let flags = Object.keys(flaggedFields);
      for(let i = 0; i < flags.length; i++){
        let key = flags[i];
        let search = new RegExp(`${key}:([^\\s]*)`);
        let matches = params.nameSearchable.match(search);
        if(matches && matches.length === 2){
          let field = flaggedFields[key];
          params[field] = matches[1];
          params.nameSearchable = params.nameSearchable.replace(matches[0], "");
        }
      }

      params.nameSearchable = params.nameSearchable.trim();      
    }

    //if the name search has more than one word, sort by score instead of last name
    //Since the flaggedFields handling may have deleted nameSearchable, check that it still exists
    if(params.nameSearchable && params.nameSearchable.indexOf(" ") > -1){
      params.sort = "score";        
    }    

    //convert the search string to uppercase
    if(params.nameSearchable && params.nameSearchable !== ""){
      params.nameSearchable = params.nameSearchable.toUpperCase();
    }
    else{
      delete params.nameSearchable;
    }

    opts['queryStringParameters'] = params;

    //translate the nextpage param
    if(params.nextPageId){
      params.ExclusiveStartKey = JSON.stringify(params.nextPageId);
    }
    delete params.nextPageId;
  }

  //add status filtering
  if(!params || !params.status){
    let statuses = Statuses
      .map((item) => item.value)
      .filter((item) => item !== "DELETED")
      .join(",");

    params.status = statuses;
  }

  // if(opts['queryStringParameters'] && !opts['queryStringParameters'].Limit){
  //   opts['queryStringParameters'].Limit = 1000;
  // }

  // console.log(params)
  return await API.get(apiName, path, opts).then(response => {
      // Add your code here
      // console.log(response)
      return response.data;
  }).catch(error => {
      console.log(error);
      return error;
  }); 
}

/**
 * [description]
 * @return {[type]} [description]
 */
export const GetAlum = async (id) => {
  let path = '/'+orgId+'/admin/alum/'+id;
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      response: true
  }

  return await API.get(apiName, path, opts).then(response => {
      // Add your code here
      return response;
  }).catch(error => {
      return error;
  }); 
}

/**
 * [description]
 * @param  {[type]} id [description]
 * @return {[type]}    [description]
 */
export const UploadUpdatefile = async (type, file) => {
  let originalName = file.name;
  let namePieces = originalName.split(".");
  if(namePieces.length){
    namePieces[namePieces.length - 1] = namePieces[namePieces.length - 1].toLowerCase();
    originalName = namePieces.join(".");    
  }


  let filename = orgId + "/" + type + "-" + originalName;

  Storage.put(filename,file)
    // .then(res => {
    //   return GetUpdatefiles()
    // })
    .catch(err => console.log("Upload error", err));
}

/**
 * [description]
 * @param  {[type]} opts [description]
 * @return {[type]}      [description]
 */
export const UploadPhoto = async ( opts ) => {
  const { profileId, fileType, fileName, image } = opts;

  let path = orgId + "/"+profileId+'-'+fileName;
  let fileopts = {contentType: fileType}

  return Storage.put(path, image, fileopts)
    .then(res => {
      if(res && res.key){
        return res.key
      } 
      else return false
    })
}

export const UpdateProfilePhoto = async ( id, key ) => {
  return updateAlum( id, 
    {set: { photo: key } }
    )
}

/**
 * [description]
 * @param  {[type]} path [description]
 * @return {[type]}      [description]
 */
export const GetPhotoUrl = async ( path ) => {
  return Storage.get( path, {bucket: 'photos.mobilealum.com'} );
}

/**
 * [description]
 * @return {[type]} [description]
 */
export const GetUpdatefiles = async (props) => {
  let search = {"limit": 10}
  if(!props){
    search.client = orgId
  }
  else{
    search = Object.assign({}, search, props);
  }

  let path = '/'+orgId+'/admin/updatefile';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      queryStringParameters: search,
      response: true
  }

  return await API.get(apiName, path, opts).then(response => {
      // Add your code here
      return response;
  }).catch(error => {
      return error;
  });  
}

/**
 * [description]
 * @return {[type]} [description]
 */
export const GetUpdatefile = async (props) => {
  let path = '/'+orgId+'/admin/updatefile/id';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      queryStringParameters: props,
      response: true
  }

  return await API.get(apiName, path, opts).then(response => {
      // Add your code here
      return response;
  }).catch(error => {
      return error;
  });  
}

/**
 * [description]
 * @param  {[type]} key [description]
 * @return {[type]}     [description]
 */
export const GetUpdatefileUrl = async (key) => {
  return await Storage.get(orgId + '/'+key, {expires: 120})
    .then(result => {return result})
    .catch(err => {return err});
}

/**
 * [description]
 * @param  {[type]} id         [description]
 * @param  {[type]} fieldnames [description]
 * @return {[type]}            [description]
 */
export const addAlumPrivateField = async (id, fieldnames) => {
  let fields = typeof fieldnames.push === "undefined" ? [fieldnames] : fieldnames;

  return updateAlum(id, {
    append: {
      privateFields: fields
    }
  })
}

/**
 * [description]
 * @param  {[type]} id         [description]
 * @param  {[type]} fieldnames [description]
 * @return {[type]}            [description]
 */
export const removeAlumPrivateField = async (id, fieldnames) => {
  let fields = typeof fieldnames.push === "undefined" ? [fieldnames] : fieldnames;

  return updateAlum(id, {
    remove: {
      privateFields: fields
    }
  })
}

/**
 * [description]
 * @param  {[type]} ids       [description]
 * @param  {[type]} newStatus [description]
 * @return {[type]}           [description]
 */
export const setAlumsStatus = async(ids, newStatus) => {
  let updates = [];
  const exists = (["DELETED", "DENIED"].indexOf(newStatus) > -1) ? "N" : "Y";

  for(let i = 0; i < ids.length; i++){
    updates.push(updateAlum(ids[i], {
      set: {
        status: newStatus,
        exists: exists
      }
      }))
  }

  return Promise.all(updates);
}

/**
 * [description]
 * @param  {[type]} id [description]
 * @return {[type]}    [description]
 */
export const RemoveAlumChangeRequest = async(id) => {
  let updates = {
    "delete": ['changeRequest', 'changeRequestTime']
  }

  return updateAlum(id, updates);
}

/**
 * [description]
 * @param  {[type]} id [description]
 * @return {[type]}    [description]
 */
export const RemoveAlumPrivacyRequest = async(id) => {
  let updates = {
    "delete": [ 'privacyNewChanges' ]
  }

  return updateAlum(id, updates)
}

/**
 * [description]
 * @param  {[type]} id      [description]
 * @param  {[type]} updates [description]
 * @return {[type]}         [description]
 */
export const updateAlum = async (id, updates) => {
  let path = '/'+orgId+'/admin/alum/'+id;
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      body: updates,
      response: true
  }

  return await API.put(apiName, path, opts).then(response => {
      // Add your code here
      // console.log("ua", response);
      return response;
  }).catch(error => {
      return error;
  });    
}

/**
 * [description]
 * @param  {[type]} fromAlum [description]
 * @param  {[type]} toAlum   [description]
 * @return {[type]}          [description]
 */
export const MatchAlum = async (fromAlum, toAlum) => {
  let path = '/'+orgId+'/admin/alum/'+fromAlum+'/match';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      body: {matchWith: toAlum},
      response: true
  }

  return await API.post(apiName, path, opts).then(response => {
      // Add your code here
      return response;
  }).catch(error => {
      return error;
  });
}

/**
 * [description]
 * @param  {[type]} userId [description]
 * @return {[type]}        [description]
 */
export const UnmatchAlum = async( userId ) => {
  return updateAlum(userId, {
    set: {
      cognitoId: "NULL",
      matched: "FALSE",
      status: "UNCLAIMED"      
    }
  })
}

/**
 * [description]
 * @param  {[type]} username [description]
 * @return {[type]}          [description]
 */
export const ResetPassword = async( username ) => {
  let path = '/'+orgId+'/admin/alum/reset-password';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      body: {
        UserPoolId: alumPool.userPoolId,
        Username: username
      },
      response: true,
  }

  return await API.post(apiName, path, opts).then(response => {
    return response;
  })
}

export const SetPassword = async( username, password ) => {
  let path = '/'+orgId+'/admin/alum/set-password';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      body: {
        UserPoolId: alumPool.userPoolId,
        Username: username,
        Password: password
      },
      response: true,
  }

  return API.post(apiName, path, opts)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error && error.response ? error.response : error
    })
}

export const GetNotificationRequests = async () => {
  let path = '/'+orgId+'/admin/notifications';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      response: true,
  }

  return await API.get(apiName, path, opts).then(response => {
    return response;
  }).catch(error => {
    return error;
  });
}

export const CreateNotificationRequest = async (payload) => {
  let path = '/'+orgId+'/admin/notifications';
  let opts = {
    headers: { 
      'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      'Content-Type': 'application/json'
    },
    body: payload,
    response: true,
  }

  return await API.post(apiName, path, opts).then(response => {
    console.log(response)
    return response;
  }).catch(error => {
    return error;
  });
}

/**
 * [description]
 * @param  {[type]} request [description]
 * @return {[type]}         [description]
 */
export const GetExport = async ( request ) => {

  if(request.nameSearchable){
    request.nameSearchable = request.nameSearchable.toUpperCase();
  }

  let path = '/'+orgId+'/admin/alum/export';
  let opts = {
      headers: { 
        'Authorization': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
        'Content-Type': 'application/json'
      },
      queryStringParameters: request,
      response: true,
  }

  return await API.get(apiName, path, opts).then(response => {
    return response;
  }).catch(error => {
      return error;
  });  
}

export const GetOption = async ( name ) => {
  let path = '/'+orgId+'/option';
  let opts = {
      headers: { 
        'Content-Type': 'application/json'
      },
      queryStringParameters: {name: name},
      response: true,
  }

  return await API.get(apiName, path, opts).then(response => {
    console.log(response);
    return response;
  }).catch(error => {
      return error;
  }); 
}

/**
 * Get secure export file URL
 * @param  {[type]} key [description]
 * @return {[type]}     [description]
 */
export const GetExportFileUrl = async (key) => {
  let fileKey = key.replace(/(\/)?public(\/)?/,"");
  return await Storage.get(fileKey, {expires: 120})
}