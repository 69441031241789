import React, {createContext, useContext, useReducer} from 'react';

export const TableDefault = {
  selected: [],
  data: []
};

export const TableContext = createContext(TableDefault);

export const TableProvider = ({reducer, initialState, children}) => {
  return (
  <TableContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </TableContext.Provider>
  )};

export const useTableValue = () => useContext(TableContext);
export const TableConsumer = TableContext.Consumer;

export const TableReducer = (state, action) => {
  switch(action.type) {
    case 'addRow':
      return {
        ...state,
        selected: [...state.selected, action.rowId]
      };
    case 'removeRow':
      return {
        ...state,
        selected: state.selected.filter((item) => item !== action.rowId)
      }
    case 'addMultipleRows':
      return {
        ...state,
        selected: [...state.selected, ...action.selected].filter(
          (value, index, self) => { return self.indexOf(value) === index }
          )
      }
    case 'removeMultipleRows':
      return {
        ...state,
        selected: state.selected.filter((value) => {
          return action.selected.indexOf(value) === -1
        })
      }
    case 'addAll':
      return {
        ...state
      }
    case 'setFilterStatus':
      return {
        ...state,
        filterStatus: action.filterStatus
      }
    case 'setFilterName':
      return {
        ...state,
        filterName: action.filterName
      }
    case 'setSort':
      return {
        ...state,
        sort: action.sort,
        sortDirection: action.sortDirection
      }
    case 'reset':
      return{
        ...state,
        selected: []
      }
    case 'setData':
      return {
        ...state,
        data: action.data
      }
    case 'appendData':
      return {
        ...state,
        data: [...state.data, ...action.data, ]
      }
    default:
      return state;
  }
}

export default TableContext;