import React, { useState } from "react";
import ViewTable from "./ViewTable";
import { Button } from 'shards-react';
import { Copy } from "../utils/config"
import { useAppValue } from '../AppContext';
import ConfirmModal from '../components/common/ConfirmModal';
import { RemoveAlumChangeRequest } from '../utils/zuriapi'

import { TableProvider, TableDefault, TableReducer } from '../components/common/TableContext';

const UpdateRequests = () => {
  const [ context, dispatch ] = useAppValue();
  const titles = [ 'Name', 'Email', 'Join Date', 'Class Year' ];
  const columns = [ 'nameFull', 'emailPrimary', 'registerDate', 'clientClassyear' ]
  const [ showConfirm, setShowConfirm ] = useState(false);
  const [ selectedAlums, setSelectedAlums ] = useState([]);
  const [ clearCallback, setClearCallback ] = useState({});

  const maybeMarkChangesComplete = (selected, callback) => {
    if(!selected || selected.length === 0){
      dispatch({
        type: 'addNotification',
        content: Copy.notifications.mustSelectUser
      })
    }
    else {
      setSelectedAlums(selected);
      setClearCallback({callback: callback});
      setShowConfirm(true);
    }    
  }

  const markChangesComplete = () => {
    let promises = selectedAlums.map(RemoveAlumChangeRequest);
    Promise.all(promises)
      .then(res => {

        if(typeof clearCallback.callback === "function"){
          clearCallback.callback();            
        }

        setShowConfirm(false);
        setSelectedAlums([]);
        setClearCallback({});        
      })
  }

  return (
  <TableProvider
    initialState={TableDefault}
    reducer={TableReducer}
    >  
    <ViewTable
      title={Copy.pageUpdatesTitle}
      filters={{
        status: "APPROVED",
        changeRequestTime: "*"
        }}
      exportProps={{
        status: "APPROVED",
        changeRequestTime: "*",
        exportType: "changeRequest"
      }}
      actions={[{
        button:(props) => (
        <Button 
          theme="secondary"
          {...props}
          >
          <i className="material-icons mr-1">check_box</i> {Copy.buttonClearChanges}
        </Button>),
        action: maybeMarkChangesComplete
        }]}
      titles={titles}
      columns={columns}
      disable={["set-status","filter-status"]}
      />
    <ConfirmModal
      show={showConfirm}
      setShow={setShowConfirm}
      title={Copy.confirmClearChangeRequestTitle}
      prompt={Copy.confirmClearChangeRequestPrompt}
      onConfirm={() => markChangesComplete()}
      />    
  </TableProvider>
  )
};


export default UpdateRequests;
