import React, { useState, useEffect } from "react";
import { 
  Container,
  Row,
  Card,
  CardBody,
  Button,
  FormRadio,
  Modal,
  ModalBody,
  ModalHeader,
  FormInput
  } from "shards-react";
import FileUpload from '../components/common/FileUpload';
import PageTitle from "../components/common/PageTitle";
import Table from "../components/common/Table";
import LoadingAnimation, { LoadingSpinner } from '../components/common/LoadingAnimation';
import Copy from '../utils/config/Copy';
import Config from '../utils/config/Config';
import { useAppValue } from '../AppContext';
import { UploadUpdatefile, GetUpdatefiles, GetUpdatefileUrl } from '../utils/zuriapi';
import dayjs from 'dayjs';

const Export = () => {
  const uploadTypes = Config.uploadTypes;
  const [ uploadType, setUploadType ] = useState(uploadTypes[0]);
  const [ file, setFile ] = useState(0);
  const [ fileList, setFileList ] = useState([]);
  const [ isUpdating, setIsUpdating ] = useState(true);
  const [ confirmedDelete, setConfirmedDelete ] = useState(false);
  const [ showConfirmDelete, setShowConfirmDelete ] = useState(false);
  const appValue = useAppValue();
  const dispatch = appValue[1];

  useEffect(() => {
    getFiles()
  }, []);

  /**
   * [description]
   * @return {[type]} [description]
   */
  const getFiles = () => {
    GetUpdatefiles({type: "Export"})
      .then(res => {
        if(res.data.items && res.data.items.length){
          //make date strings readable
          res.data.items.map((item) => {
            item.uploaddate = dayjs(item.uploaddate).format(Copy.datetimeFormat);
            return item;
          })

          setFileList(res.data.items)          
        }
        setIsUpdating(false);
      })
      .catch(error => {
        setIsUpdating(false);
      })    
  }

  /**
   * [description]
   * @param  {[type]} res [description]
   * @return {[type]}     [description]
   */
  const refreshUploads = async(res) => {
    setFile(null);
    setIsUpdating(true);
    setTimeout(getFiles, 10000);
    }

  return (
  <Container fluid className="main-content-container px-4 pb-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle sm="4" title={Copy.exportTitle} className="text-sm-left" />
    </Row>
    {
      fileList.length ? 
        fileTable(fileList, getFiles)
        : React.Fragment      
    }
  </Container>
  )


};

/**
 * [description]
 * @param  {[type]} data [description]
 * @return {[type]}      [description]
 */
const fileTable = ( data, refreshAction ) => {  
  const refreshButton = (
    <Button
      onClick={(e) => refreshAction()}
      >Refresh</Button>
    );

  return Table({
    title: [refreshButton],
    note: "Showing 10 most recent imports",
    data: data,
    columnKeys: ["type", "status", "filename", "uploaddate", "successCount", "failureCount"],
    columnTitles: ["Type", "Status", "File Name" ,"Upload Date", "Exported", "Errors"],
    rowActions: (row) => (
        <Button
          onClick={(e) => {
            e.preventDefault();
            makeDownloadLink(decodeURIComponent(row.filename))
          }}
          >Download</Button>
      )
    });
}

/**
 * [description]
 * @param  {[type]} key [description]
 * @return {[type]}     [description]
 */
const makeDownloadLink = (key) => {
  GetUpdatefileUrl(key)
    .then(res => window.location = res)
    .catch(err => console.log("e", err));
} 

export default Export;